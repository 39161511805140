import { mq } from '../../cssInJs';

export default {
  products: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  productCol: mq({
    flex: ['1 0 100%', '1 0 50%', '1 0 25%'],
    display: 'flex',
    flexDirection: 'column',
    maxWidth: [null, '50%', '25%' ]
  }),
}