import React from 'react';
import utils from '../../utils';
import { SiteLink } from '../../components';

export function Tile({ product }) {
  const imageUrl = product.productThumbnail ? utils.site.resourcePath(product.productThumbnail) : '/assets/product-empty.png';
  const productUrl = product.productUrl ? product.productUrl : `/product/${product.productCode}`;

  return (
    <div className="productTile tile">
        <SiteLink className="productTile__link tile__content" to={productUrl}>
          <div className="tile__bg" style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
          <div className="productTile__title">
            {product.productName}
            <div className="productTile__inlineRating">
              <div data-bv-show="inline_rating" data-bv-seo="false" data-bv-product-id={product.productCode}></div>
            </div>
          </div>

          <div className="productTile__price">
            {product.priceText ? product.priceText : ''}
          </div>
        </SiteLink>
    </div>
  )
}