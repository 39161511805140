import { mq } from '../../../cssInJs';

export default{
    ksjb__page__menu__bar : mq({
        backgroundColor: 'white',
        marginTop: ['1rem',null,'3rem'],
        position:'sticky',
        top: ['44px',null,'56px'],
        zIndex: 999,
        // paddingTop: '0.3rem',
        // paddingBottom: '0.3rem'
    }),
    menu__bar : mq({
        display: 'flex',
        maxWidth: ['360px',null,'1000px'],
        marginLeft: 'auto',
        marginRight: 'auto'
    }),
    bar__item : {
        flex: '1 0 auto',
        textAlign: 'center',
        //backgroundColor: 'red'
    },
    bar__item__title : mq({
        //borderBottom: '3px solid red',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        display:'inline-block',
        fontSize: ['15px',null,'20px'],
        fontWeight: 600,
        color: 'black',
        cursor: 'pointer',
        position: 'relative'

        // '&.selected' : {
        //     borderBottom: '3px solid black',
        // }
    }),
    bar__item__line : {
        marginTop:'0px',
        marginBottom: '0px',
        position: 'absolute',
        width: '100%',
        bottom: '0px',
        borderTop: '3px solid black',
        display: 'none',
        '&.selected' : {
            display:'block'
        }
    }
}