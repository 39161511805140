import { mq } from '../../cssInJs';

export default{

    // video__embed__container : mq({
    //     height: ['55vh',null,'88vh']
    // }),

    video__embed__video__bg : {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        cursor: 'pointer'
    },

    video__embed__video : mq({
        // width: ['220%',null,'auto'],
        // height: 'auto',
        // position:'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        // minHeight: '100%',
        // minWidth: '101%'
        width : '100%'
    }),

    // video__embed__text : mq({
    //     position:'absolute',
    //     color: 'black',
    //     fontWeight: 700,
    //     fontSize: ['28px',null,'52px'],
    //     lineHeight:['30px',null,'55px'],
    //     width:'100%',
    //     left: '50%',
    //     bottom: '10%',
    //     textAlign: 'center',
    //     transform:'translateX(-50%)',
    // })
}