function payload(item, key, defaultVal = '') {
  const found = item.payloads.find(x => x.key === key);
  return found ? found.value : defaultVal;
}

function findItemByTypeCode(pageData, itemTypeCode) {
  if (!pageData) return null;
  for (let i = 0; i < pageData.sections.length; i++) {
    const section = pageData.sections[i];
    const found = findItem(section.items, itemTypeCode);
    if (found) return found;
  }
  return null;
}

function findItem(items, itemTypeCode) {
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.itemTypeCode === itemTypeCode) {
      return item;
    }
    const found = findItem(item.items, itemTypeCode);
    if (found) {
      return found;
    }
  }
  return null;
}

export default { payload, findItemByTypeCode };