/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './footerStyle';
import { SiteLink } from '../../components';

export function Footer(props) {
  const year = (new Date()).getFullYear();
  //const ver = env.ver;

  return (
    <div css={style.footerWrapper} id="bottom-panel">
      <div css={style.footer}>
        <div css={style.logo}>
          <img css={style.logoImg} src="/assets/logos/logo.png" alt="logo" />
        </div>

        <div className="row" css={style.socialAndLinks}>
          <div className="col-md-3">

            <SocialIcons />
          </div>
          <div className="col-md-9">
            <div css={style.links} className="footerLinks">
              <ul>
                <li>&copy;{year} Cuisinart - Conair Australia</li>
                <li>All rights reserved</li>
                <li><SiteLink css={style.links} to="/privacy"> Privacy Policy</SiteLink></li>
                <li><SiteLink css={style.links} to="/disclaimer">Disclaimer</SiteLink></li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="go-top">
        <SiteLink to="#top">
          <img src={`${env.resourceBase}/assets/go-to-top.png`} alt="Go to top" />
        </SiteLink>
      </div> */}
      </div>
    </div>
  );
}

function SocialIcons() {
  return (
    <div css={style.social}>
      <a css={style.socialIcon} className="socials__facebook" href="https://www.facebook.com/CuisinartAustralia" target="_blank" rel="noopener noreferrer" title="Facebook" aria-label="Facebook">
        <span className="sr-only sr-only-focusable">Facebook</span>
      </a>
      <a css={style.socialIcon} className="socials__youtube" href="https://www.youtube.com/channel/UCLG_Nj8VQ37SJtAwMv2kRdw" target="_blank" rel="noopener noreferrer" title="Youtube" aria-label="Youtube">
        <span className="sr-only sr-only-focusable">Youtube</span>
      </a>
      <a css={style.socialIcon} className="socials__instagram" href="https://instagram.com/cuisinart_au" target="_blank" rel="noopener noreferrer" title="Instagram" aria-label="Instagram">
        <span className="sr-only sr-only-focusable">Instagram</span>
      </a>
    </div>
  )
}

