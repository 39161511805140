/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useMemo } from 'react';
import style from './style';
import utils from '../../../utils';
import { FacebookShareButton } from "react-share";
import env from '../../../env';
//import { useRouter } from 'components';

export function QuizPanel(props) {
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'ksjb__page__quiz__panel', item.cssClass || item.anchorName || '');
    //const { query } = useRouter();
    const [curruentStep, setCurruentStep] = useState(0);
    const [answerResult, setAnswerResult] = useState({ step1: '', step2: '', step3: '', step4: '', step5: '', step6: '' });
    const baseShareUrl = env.baseOrigin + cmsOption.pageData.pageUrl;

    const curruentStepMemo = useMemo(() => {
        return curruentStep;
    }, [curruentStep]);

    const answerResultMemo = useMemo(() => {
        return answerResult;
    }, [answerResult]);

    const resetQuiz = (() => {
        setAnswerResult({ step1: '', step2: '', step3: '', step4: '', step5: '', step6: '' });
        setCurruentStep(1);
    });

    //id={item.anchorName}
    return (
        <div className={cssClass} css={style.ksjb__page__quiz__panel} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id="quiz-panel">

            <div className='quiz__panel__wave' css={style.quiz__panel__wave}>
                <svg width="1440px" height="25px" viewBox="0 0 1440 25" preserveAspectRatio="none">
                    <g data-svg-origin="0 0">
                        <path fill="#ABC87A" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 25 H 0 V 0"></path>
                    </g>
                </svg>
            </div>

            <div className='m_floating-carrot' css={style.m_floating_carrot}>
                <img src='/assets/carrot.png?v=1' alt="carrot" />
            </div>

            <div className='m_floating-spinach-below' css={style.floating_spinach_below}>
                <img src='/assets/spinach-below-blur25.png?v=1' alt='spinach' />
            </div>

            <div className='m_floating-strawberry' css={style.m_floating_strawberry}>
                <img src='/assets/strawberry.png?v=1' alt='strawberry' />
            </div>

            <div className='ksjb__page__quiz__inner__panel' css={style.ksjb__page__quiz__inner__panel}>
                <div className='panel__container' css={style.panel__container} style={{ transform: `translateX(${-100 * curruentStepMemo}%)` }}>
                    <StepTitle setCurruentStep={setCurruentStep} />
                    <Step1 setCurruentStep={setCurruentStep} setAnswerResult={setAnswerResult} stepAnswer={answerResultMemo.step1} />
                    <Step2 setCurruentStep={setCurruentStep} setAnswerResult={setAnswerResult} stepAnswer={answerResultMemo.step2} />
                    <Step3 setCurruentStep={setCurruentStep} setAnswerResult={setAnswerResult} stepAnswer={answerResultMemo.step3} />
                    <Step4 setCurruentStep={setCurruentStep} setAnswerResult={setAnswerResult} stepAnswer={answerResultMemo.step4} />
                    <Step5 setCurruentStep={setCurruentStep} setAnswerResult={setAnswerResult} stepAnswer={answerResultMemo.step5} />
                    <Step6 setCurruentStep={setCurruentStep} setAnswerResult={setAnswerResult} stepAnswer={answerResultMemo.step6} />

                    {
                        answerResult.step1 !== '' && answerResult.step2 !== '' && answerResult.step3 !== '' &&
                        answerResult.step4 !== '' && answerResult.step5 !== '' && answerResult.step6 !== '' &&
                        <ResultStep answerResult={answerResult} resetQuiz={resetQuiz} baseShareUrl={baseShareUrl} />
                    }

                </div>
            </div>
        </div>

    )
}

function StepTitle({ setCurruentStep }) {
    return (
        <div className='step__title__container' css={style.step__title__container}>
            <div className='row row__style quiz__panel__title__container' css={style.row__style}>
                <div className='col-12 col-md-6' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                    <div className='quiz__panel__title' css={style.quiz__panel__title}>
                        <div className='first__text'> QUIZ </div>
                        <div className='second__text'> WHAT SIGNATURE <br /> DRINK ARE YOU? </div>

                        <div className='third__text desk__top'>
                            Find out what drink best represents you. <br />
                            Just answer a few fun questions!
                        </div>

                        <div className='third__text mobile'>
                            You may know what juice or smoothie you enjoy…
                            but do you know what kind of a drink best embodies you?
                            Answer the following quiz questions to peel away the layers and get to the very core of who you might be! Pardon the puns.
                            Let's begin.
                        </div>

                        <div className='button__text' onClick={() => {
                            setCurruentStep(1);
                        }}>
                            TAKE THE QUIZ
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='quiz__panel__img' css={style.quiz__panel__img}>
                        <img src='/sites/cuisinart/media/kick-start-landing-page/quiz-results3.gif' alt='drink__change' />
                        {/* <video style={{width:'100%'}} autoPlay="autoplay" loop poster="/sites/cuisinart/media/kick-start-landing-page/drinks-change.jpg" muted playsInline>
                            <source src="/sites/cuisinart/media/kick-start-landing-page/quiz-drinks-change.m4v" type="video/mp4" />
                        </video> */}
                    </div>
                    <div className='quiz__panel__title' css={style.quiz__panel__title}>
                        <div className='button__text mobile' onClick={() => {
                            setCurruentStep(1);
                        }}>
                            TAKE THE QUIZ
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

function Step1({ setCurruentStep, setAnswerResult, stepAnswer }) {

    const answers = [
        { answerId: 'step1_a', answerIndex: 0, quizDes: "A dwarf bunny that's just surrendered to the shelter." },
        { answerId: 'step1_b', answerIndex: 1, quizDes: "A lioness (yup, not even a lion)." },
        { answerId: 'step1_c', answerIndex: 2, quizDes: "A platypus with an identity crisis." },
        { answerId: 'step1_d', answerIndex: 3, quizDes: "The endangered bumblebee." },
        { answerId: 'step1_e', answerIndex: 4, quizDes: "A lynx dressed in haute couture." },
        { answerId: 'step1_f', answerIndex: 5, quizDes: "A schnauzer that knows all the tricks." },
    ];

    const text1 = "QUESTION 1";
    const text2 = "What is your spirit animal?";
    const stepId = "step1";

    return (
        <QuizDetails answers={answers} setCurruentStep={setCurruentStep} text1={text1} text2={text2} stepId={stepId} setAnswerResult={setAnswerResult} stepAnswer={stepAnswer} />
    )
}

function Step2({ setCurruentStep, setAnswerResult, stepAnswer }) {

    const answers = [
        { answerId: 'step2_a', answerIndex: 0, quizDes: "I don't dance. I generally hide in the corner." },
        { answerId: 'step2_b', answerIndex: 1, quizDes: "It's my own dance routine. You don't know it." },
        { answerId: 'step2_c', answerIndex: 2, quizDes: "It's twerking mixed with a bit of ballet." },
        { answerId: 'step2_d', answerIndex: 3, quizDes: "An interpretive dance that speaks of what society has become." },
        { answerId: 'step2_e', answerIndex: 4, quizDes: "Go away. I'm not breaking a sweat in my designer threads." },
        { answerId: 'step2_f', answerIndex: 5, quizDes: "Anything that's super on trend right now on TikTok!" },
    ];

    const text1 = "QUESTION 2";
    const text2 = "What is your go-to dance move at a party?";
    const stepId = "step2";

    return (
        <QuizDetails answers={answers} setCurruentStep={setCurruentStep} text1={text1} text2={text2} stepId={stepId} setAnswerResult={setAnswerResult} stepAnswer={stepAnswer} />
    )
}

function Step3({ setCurruentStep, setAnswerResult, stepAnswer }) {

    const answers = [
        { answerId: 'step3_a', answerIndex: 0, quizDes: "A supercar with tinted windows." },
        { answerId: 'step3_b', answerIndex: 1, quizDes: "A convertible with the top down. Always." },
        { answerId: 'step3_c', answerIndex: 2, quizDes: "Must it be a car? I'd like a motorbike instead." },
        { answerId: 'step3_d', answerIndex: 3, quizDes: "Anything that runs on renewable energy." },
        { answerId: 'step3_e', answerIndex: 4, quizDes: "A top-of-the-line model of any expensive Italian car brand." },
        { answerId: 'step3_f', answerIndex: 5, quizDes: "Anything is fine. The focus is always on me anyway." },
    ];

    const text1 = "QUESTION 3";
    const text2 = "Describe your dream car.";
    const stepId = "step3";

    return (
        <QuizDetails answers={answers} setCurruentStep={setCurruentStep} text1={text1} text2={text2} stepId={stepId} setAnswerResult={setAnswerResult} stepAnswer={stepAnswer} />
    )
}

function Step4({ setCurruentStep, setAnswerResult, stepAnswer }) {

    const answers = [
        { answerId: 'step4_a', answerIndex: 0, quizDes: "Joan of Arc, because of the sacrifices she made." },
        { answerId: 'step4_b', answerIndex: 1, quizDes: "Coco Chanel, for always setting trends." },
        { answerId: 'step4_c', answerIndex: 2, quizDes: "Charlie Chaplin. Or maybe Björk. Or RuPaul." },
        { answerId: 'step4_d', answerIndex: 3, quizDes: "Greta Thunberg, for raising awareness about global warming." },
        { answerId: 'step4_e', answerIndex: 4, quizDes: "Elon Musk, for making all that cash." },
        { answerId: 'step4_f', answerIndex: 5, quizDes: "Lady Gaga, just because." },
    ];

    const text1 = "QUESTION 4";
    const text2 = "Name an iconic figure you admire";
    const stepId = "step4";

    return (
        <QuizDetails answers={answers} setCurruentStep={setCurruentStep} text1={text1} text2={text2} stepId={stepId} setAnswerResult={setAnswerResult} stepAnswer={stepAnswer} />
    )
}

function Step5({ setCurruentStep, setAnswerResult, stepAnswer }) {

    const answers = [
        { answerId: 'step5_a', answerIndex: 0, quizDes: "Pink, because it's delicate." },
        { answerId: 'step5_b', answerIndex: 1, quizDes: "Orange, because it reminds me of a bright sun." },
        { answerId: 'step5_c', answerIndex: 2, quizDes: "Blue. Because it's almost a blanket term for the colours  mistaken as indigo, turquoise and more." },
        { answerId: 'step5_d', answerIndex: 3, quizDes: "Red, because it's the colour of the blood that runs through our veins." },
        { answerId: 'step5_e', answerIndex: 4, quizDes: "Green, because I'm always envious of what others have that I don't!" },
        { answerId: 'step5_f', answerIndex: 5, quizDes: "Yellow, because it's bright!" },
    ];

    const text1 = "QUESTION 5";
    const text2 = "What's your favourite colour?";
    const stepId = "step5";

    return (
        <QuizDetails answers={answers} setCurruentStep={setCurruentStep} text1={text1} text2={text2} stepId={stepId} setAnswerResult={setAnswerResult} stepAnswer={stepAnswer} />
    )
}

function Step6({ setCurruentStep, setAnswerResult, stepAnswer }) {

    const answers = [
        { answerId: 'step6_a', answerIndex: 0, quizDes: "Up in the mountains where I can reflect on life." },
        { answerId: 'step6_b', answerIndex: 1, quizDes: "Paris, because it's the place to be inspiring and inspired." },
        { answerId: 'step6_c', answerIndex: 2, quizDes: "Ecuador, because it's super exotic." },
        { answerId: 'step6_d', answerIndex: 3, quizDes: "Antarctica, because I want to experience icy wonders." },
        { answerId: 'step6_e', answerIndex: 4, quizDes: "Anywhere I get treated like royalty." },
        { answerId: 'step6_f', answerIndex: 5, quizDes: "Rio de Janeiro! For all the non-stop Carnival parties." },
    ];

    const text1 = "QUESTION 6";
    const text2 = "What's your dream holiday destination?";
    const stepId = "step6";

    return (
        <QuizDetails answers={answers} setCurruentStep={setCurruentStep} text1={text1} text2={text2} stepId={stepId} setAnswerResult={setAnswerResult} stepAnswer={stepAnswer} />
    )
}

function QuizDetails({ answers, setCurruentStep, text1, text2, stepId, setAnswerResult, stepAnswer }) {

    return (
        <div id={'step__title__container__' + stepId} className='step__title__container' css={style.step__title__container}>
            <div className='row row__style' css={style.row__style} style={{ marginLeft: '0px', marginRight: '0px' }}>
                <div className='col-12'>

                    <div id={'step__id__' + stepId} className='quiz__detail__text1' css={style.quiz__detail__text1}> {text1} </div>
                    <div className='quiz__detail__text2' css={style.quiz__detail__text2}> {text2} </div>

                    <div id={'step__answers__id__' + stepId} className='quiz__detail__answers' css={style.quiz__detail__answers}>
                        {
                            answers.map((item, index) =>
                                <div key={index} className="quiz__detail__answer" css={style.quiz__detail__answer} onClick={() => {
                                    setAnswerResult((c) => { return { ...c, [stepId]: item.answerId } });
                                }}>
                                    <div className={`quiz__detail__answer__des ${stepAnswer === item.answerId ? 'selected' : ''}`} css={style.quiz__detail__answer__des}>
                                        <div>
                                            {item.quizDes}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className='quiz__detail__arrow' css={style.quiz__detail__arrow}>

                        {
                            stepId !== 'step1' &&
                            <img src='/assets/arrow-left.svg' alt='arrow' onClick={() => {
                                setCurruentStep((c) => c - 1);
                            }} />
                        }

                        {
                            stepId !== 'step6' &&
                            <img src='/assets/arrow-right.svg' alt='arrow' className={`${stepAnswer !== '' ? 'enable' : 'none'}`} onClick={() => {
                                setCurruentStep((c) => c + 1);
                            }} />
                        }
                    </div>
                    {
                        stepId === 'step6' &&
                        <div className={`submit__button ${stepAnswer !== '' ? 'enable' : 'none'}`} css={style.submit__button} onClick={() => {
                            if (window.innerWidth <= 576) {
                                utils.ui.scrollTo("#result__step__detail__item");
                                setTimeout(() => {
                                    //utils.ui.scrollTo("#result__step__detail__item");
                                    setCurruentStep((c) => c + 1);
                                }, 500);
                            }
                            else {
                                setCurruentStep((c) => c + 1);
                            }
                        }}>
                            SUBMIT
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

function ResultStep({ answerResult, resetQuiz, baseShareUrl }) {
    const resultMemo = useMemo(() => {
        let mArray = [
            { resultId: 'a', point: 0 },
            { resultId: 'b', point: 0 },
            { resultId: 'c', point: 0 },
            { resultId: 'd', point: 0 },
            { resultId: 'e', point: 0 },
            { resultId: 'f', point: 0 }
        ];

        for (let i = 1; i <= 6; i++) {
            if (answerResult["step" + i].includes("_a")) {
                mArray[0].point = mArray[0].point + 1;
            }
            else if (answerResult["step" + i].includes("_b")) {
                mArray[1].point = mArray[1].point + 1;
            }
            else if (answerResult["step" + i].includes("_c")) {
                mArray[2].point = mArray[2].point + 1;
            }
            else if (answerResult["step" + i].includes("_d")) {
                mArray[3].point = mArray[3].point + 1;
            }
            else if (answerResult["step" + i].includes("_e")) {
                mArray[4].point = mArray[4].point + 1;
            }
            else if (answerResult["step" + i].includes("_f")) {
                mArray[5].point = mArray[5].point + 1;
            }
        }

        mArray.sort((a, b) => a.point - b.point).reverse();

        let _result = { id: '', title: '', title2: '', img: '', imgText: '', des: '', shareUrl: '' };
        switch (mArray[0].resultId) {
            case 'a':
                _result.id = 'a';
                _result.title = 'You are an';
                _result.title2 = 'Easily Bruised Strawberry Smoothie';
                _result.img = 'sb.png';
                _result.imgText = 'quiz-results-01-strawberry-smoothie.png';
                _result.des = "Don't let the world get to you. There are so many amazing things out there to enjoy! Take everything with a pinch of salt (figuratively) and embrace the goodness of everyone and everything around you. Have fun!";
                _result.shareUrl = baseShareUrl + '?quizid=a';
                break;
            case 'b':
                _result.id = 'b';
                _result.title = 'You are the';
                _result.title2 = 'OG OJ';
                _result.img = 'oj.png';
                _result.imgText = 'quiz-results-02-avocado-smoothie.png';
                _result.des = "You are a super confident individual who believes in being the ultra trendsetter. You say no to imitations and will not stand for being a copycat. People around look to you for fashion inspiration, design trends, and even what juices and smoothies to make!";
                _result.shareUrl = baseShareUrl + '?quizid=b';
                break;
            case 'c':
                _result.id = 'c';
                _result.title = 'You are a';
                _result.title2 = 'Quirky Blueberry And Blackcurrant Smoothie';
                _result.img = 'berry.png';
                _result.imgText = 'quiz-results-03-blueberry-blackcurrant-smoothie.png';
                _result.des = "A superfruit combo? That's you. You are equal parts unique and unpredictable. And that thoroughly fascinates people around you. You are different. Not just because it's awesome to be, but because that's just who you are.";
                _result.shareUrl = baseShareUrl + '?quizid=c';
                break;
            case 'd':
                _result.id = 'd';
                _result.title = 'You are a';
                _result.title2 = 'Woke Watermelon Juice';
                _result.img = 'wm.png';
                _result.imgText = 'quiz-results-04-watermelon-juice.png';
                _result.des = "You're totes everyone's cup of tea. Or rather, juice. Always making sure people around are well taken care of, you are always on the lookout for friends who can do with a bit of help. Like, OMG, your loving spirit is what attracts people to you.";
                _result.shareUrl = baseShareUrl + '?quizid=d';
                break;
            case 'e':
                _result.id = 'e';
                _result.title = 'You are an';
                _result.title2 = 'Expensive Avocado Smoothie';
                _result.img = 'avo.png';
                _result.imgText = 'quiz-results-05-avocado-smoothie.png';
                _result.des = "You have a rich taste. You seek the finer things in life and will never settle for second best.  Everything about you is simply divine and immaculate because… why not? It don't mean a thing if it ain't got that bling.";
                _result.shareUrl = baseShareUrl + '?quizid=e';
                break;
            case 'f':
                _result.id = 'f';
                _result.title = 'You are a';
                _result.title2 = 'Life-Of-The-Party Lime-Lemonade';
                _result.img = 'lemon.png';
                _result.imgText = 'quiz-results-06-lime-lemonade.png';
                _result.des = "Oh wow. When you enter the room, everyone turns to look at you. Why? You're super zesty, fun, and energetic. Your energy is dynamic and everyone is drawn to you. No one's as cool and popular as you.";
                _result.shareUrl = baseShareUrl + '?quizid=f';
                break;
            default:
                break;
        }

        return _result;

        // eslint-disable-next-line
    }, [answerResult]);

    console.log("resultMemo", resultMemo);

    return (
        <div id='result__step__item' className='result__step' css={style.result__step}>
            <div className='row row__style' css={style.row__style} style={{ marginLeft: '0px', marginRight: '0px' }}>
                <div className='col-12'>

                    <div id='result__step__detail__item' className='result__step__detail' css={style.result__step__detail}>

                        <div className='result__step__detail__p left'>
                            <div className='result__step__detail__title'>{resultMemo.title}</div>
                            {/* <div className={`result__step__detail__title2 ${resultMemo.id}`}>
                                {resultMemo.title2}
                            </div> */}
                            <div className='result__step__detail__img__text'>
                                <img src={'/assets/quiz/' + resultMemo.imgText} alt="img_text" />
                            </div>
                            <div className='result__step__detail__des'>
                                {resultMemo.des}
                            </div>
                        </div>
                        <div className='result__step__detail__p right'>
                            <div className='result__step__detail__img'>
                                <img src={'/assets/quiz/' + resultMemo.img} alt="step_img" />
                            </div>
                        </div>
                    </div>

                    {/* <div className='result__step__social' css={style.result__step__social}>
                        <FacebookShareButton url={resultMemo.shareUrl} title="Sharing with Facebook">
                            <div className='result__step__social__container'> <img src='/assets/social/fb.png' alt="fb" /> </div>
                        </FacebookShareButton>
                    </div> */}

                    <div className='result__step__bottom__text' css={style.result__step__bottom__text}>

                        <div className='result__step__social__v2' css={style.result__step__social__v2}>
                            <div className='result__step__social' css={style.result__step__social}>
                                <FacebookShareButton url={resultMemo.shareUrl} title="Sharing with Facebook">
                                    <div className='result__step__social__container'> <img src='/assets/social/fb.png' alt="fb" /> </div>
                                </FacebookShareButton>
                            </div>
                            <div className='result__step__bottom__text__v2' css={style.result__step__bottom__text__v2}>
                                <div className='result__step__bottom__text__1'>Describes you to a T?</div>
                                <div className='result__step__bottom__text__2'>Share it on Facebook!</div>
                            </div>
                        </div>

                        {/* <div className='result__step__bottom__text__1'>Describes you to a T?</div>
                        <div className='result__step__bottom__text__2'>Share it on Facebook!</div> */}


                        <div className='result__step__bottom__text__3'>
                            Doesn't sound like you? Take the <span className='result__step__quiz__btn' onClick={() => {
                                resetQuiz();
                                if (window.innerWidth <= 576) {
                                    utils.ui.scrollTo("#step__title__container__step1");
                                }
                            }}>quiz</span> again!
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}