import { css } from '@emotion/core'
import { variables, mq } from '../../../cssInJs'
import { rgba } from 'polished'

const breakpoints = [1, 2, 2, 3]
const marginLR = [14, 14, 14, 14]
const marginTB = [14, 14, 14, 14]

const bpPer = (index) => 100 / breakpoints[index] + '%'

export default {
  holder: css(
    mq({
      maxWidth: 1600,
      margin: 'auto',
      marginTop: [30, null, 40],
      marginBottom: [30, null, 40],
      '.slick-prev, .slick-next': {
        top: '70px',
        transform: 'translate(0, 0)',
      },
      '.slick-prev': {
        left: [10, null, -20],
      },
      '.slick-next': {
        right: [10, null, -20],
      },
      '.slick-prev:before, .slick-next:before': {
        color: variables.fontColor,
      },
    })
  ),
  tiles: css(
    mq({
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      marginLeft: [
        -marginLR[0] / 2,
        -marginLR[1] / 2,
        -marginLR[2] / 2,
        -marginLR[3] / 2,
      ],
      marginRight: [
        -marginLR[0] / 2,
        -marginLR[1] / 2,
        -marginLR[2] / 2,
        -marginLR[3] / 2,
      ],
      marginTop: [
        marginTB[0] / 2,
        marginTB[1] / 2,
        marginTB[2] / 2,
        marginTB[3] / 2,
      ],
      marginBottom: [
        marginTB[0] / 2,
        marginTB[1] / 2,
        marginTB[2] / 2,
        marginTB[3] / 2,
      ],
    })
  ),
  tilesSlider: css(
    mq({
      display: 'block',
    })
  ),
  tile: css(
    mq({
      paddingLeft: [
        marginLR[0] / 2,
        marginLR[1] / 2,
        marginLR[2] / 2,
        marginLR[3] / 2,
      ],
      paddingRight: [
        marginLR[0] / 2,
        marginLR[1] / 2,
        marginLR[2] / 2,
        marginLR[3] / 2,
      ],
      paddingTop: [
        marginTB[0] / 2,
        marginTB[1] / 2,
        marginTB[2] / 2,
        marginTB[3] / 2,
      ],
      paddingBottom: [
        marginTB[0] / 2,
        marginTB[1] / 2,
        marginTB[2] / 2,
        marginTB[3] / 2,
      ],
      maxWidth: [bpPer(0), bpPer(1), bpPer(2), bpPer(3)],
      flexBasis: [bpPer(0), bpPer(1), bpPer(2), bpPer(3)],
      flexGrow: 0,
      flexShrink: 0,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
    })
  ),
  linkWrapper: css({
    height: '100%',
    position: 'relative',
    textDecoration: 'none',
    borderBottom: `1px solid ${rgba(variables.fontColor, 0.3)}`,
    '&:hover, &:focus': {
      textDecoration: 'none',
      '.tileTitle': {
        background: variables.fontColor,
        color: 'white',
        textDecoration: 'none',
        paddingLeft: '0.7rem',
      },
    },
  }),
  tileSlider: css(
    mq({
      maxWidth: '100% !important',
    })
  ),
  tileBg: css(
    mq({
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: 0,
      paddingBottom: '100%',
    })
  ),
  title: css(
    mq({
      width: '100%',
      //transition: 'background-color 0.7s, color 0.25s, font-weight 0.25s',
      transition: 'all 0.4s',
      textTransform: 'uppercase',
      //background: 'rgba(255, 255, 255, 0.8)',
      color: variables.fontColor,
      fontFamily: variables.familyHeader,
      fontWeight: 'bold',
      textAlign: 'left',
      // padding: ['0.3rem 0.5rem', '0.6rem 1rem', null, '0.6rem 1rem 0.6rem 3rem'],
      // fontSize: ['1rem', '1.2rem', null, '1.6rem'],
      padding: ['0.3rem 0'],
      fontSize: ['1rem', null, null, '1rem'],
    })
  ),
}
