import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useRouter } from './components';
import { StoreProvider } from './store';
import { ViewPage } from './cms/pages/viewPage/viewPage';
import { EditPage } from './cms/pages/editPage/editPage';
import { TopHeader } from './layout/topHeader/topHeader';
import { Footer } from './layout/footer/footer';
import { BottomDock } from './layout/bottomDock/bottomDock';
import { ProductView } from './site/product/productView';
// import { BlogView } from './site/blog/blogView';
import { AppVersionPage } from './components';
import { MediaQueryStatus } from './components';
import { CommonVideoPopup } from "./layout/popup/commonVideoPopup";
import { HelmetProvider } from 'react-helmet-async';
//import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { AppRouteChanged } from './AppRouteChanged';
import { checkUpdate } from './lib/service-worker/swHelper';
import { GourmetProLoading } from "./site/gourmetPro/gourmetProLoading";

function AppLayout(props) {
  const { pathname } = useRouter();

  React.useEffect(() => {
    const fetchData = async () => {
      await checkUpdate();  
    }
    fetchData();
  }, [pathname]);

  //const [scrolled, setScrolled] = useState(false)
  //const path = usePath();
  //const routeResult = useRoutes(routes);

  // const parallax = useParallax();
  // //console.log(path, routeResult);
  // const routeChanged = (path) => {
  //   //console.log(path);
  //   parallax.reset();
  // }

  // useScrollPosition(({ prevPos, currPos }) => {
  //   //console.log(currPos.x)
  //   //console.log(currPos.y);
  //   if (currPos.y > 90) {
  //     document.querySelector('body').classList.add('scrolled')
  //   } else {
  //     document.querySelector('body').classList.remove('scrolled')
  //   }
  //   //setScrolled(currPos.y > 90)
  // }, [], null, true)

  //const pageChanged = useCallback(path => routeChanged(path), [])
  //useLocationChange((path) => { routeChanged(path, history)})
  //useLocationChange(path => routeChanged(path))
  
  //const pathFiltered = (pathname || '').toLocaleLowerCase();
  let specialClassName = '';
//  if (pathFiltered.indexOf('/digital-sensor') >= 0) specialClassName = 'digital-sensor-page';
//  else if (pathFiltered === '/brilliance') specialClassName = 'brilliance-page';

  return (
    <div className={`body-content ${pathname === '/' ? 'homepage' : 'subpage'} ${(pathname === '/test' ||  pathname === '/kick-start-juicer-blender' || pathname === '/cms/page/edit/222911b9-7e9a-4420-894f-365a7a61a41a' || pathname === '/kickstart') ? 'kick__start__juicer__blender__page' : 'normal__page'}
      ${specialClassName} ${pathname.includes('gourmet-pro') ? 'gourmet-pro' : ''}`}>
      <AppRouteChanged />
      <TopHeader></TopHeader>
      <div className="main-content">
        <div className="app-content">
          <Switch>
            <Route path="/ver" children={<AppVersionPage />} />
            <Route path="/cms/page/render/:id" children={<EditPage />} />
            <Route path="/products/:slug" children={<ProductView />} />

            <Route path="/gourmet-pro-stg" children={<GourmetProLoading />} /> 
            {/* <Route path="/blog/:id" children={<BlogView />} /> */}
            
            <Route path="/admin">
              <Redirect to="/admin" />
            </Route>
            <Route path="/">
              <ViewPage />
            </Route>
          </Switch>
        </div>
        {/* <app-common-bottom-partial></app-common-bottom-partial> */}
      </div>
      <Footer></Footer>
      <BottomDock></BottomDock>
      <MediaQueryStatus />
      <CommonVideoPopup />
    </div>
  );
}

function App() {
  return (
    <StoreProvider>
      <HelmetProvider>
      <Router>
        <AppLayout />
      </Router>
      </HelmetProvider>
    </StoreProvider>
  );
}

export default App;
