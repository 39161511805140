/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from '../../utils';
import { SiteLink } from '../../components';

export function Tile({ product, style, useFullHeight }) {
  const imageUrl = product.productThumbnail ? utils.site.resourcePath(product.productThumbnail) : '/assets/product-empty.png';
  //console.log(imageUrl)
  const productUrl = product.productUrl ? product.productUrl : `/product/${product.productCode}`;
  return (
    <div css={[style.tile, useFullHeight && { height: '100%' }]}>
      <SiteLink css={style.tileLink} to={productUrl}>
        <div css={style.prdImage}>
          <div css={style.bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        </div>
        <div css={style.name}>
          {product.productName}
          {/* <div className="productTile__inlineRating">
            <div data-bv-show="inline_rating" data-bv-product-id={product.productCode}></div>
          </div> */}
        </div>

        <div css={style.price}>
          {product.priceText ? product.priceText : ''}
        </div>

      </SiteLink>
    </div>
  )
}