import React, { Fragment } from 'react'
import env from '../../env'
import { useGet } from '../../components'
import { ErrorPopup, Loading } from '../../components'

export function WhereToBuyList(props) {
  const get = useGet()
  React.useEffect(() => {
    get.send(env.apiBase + '/api/store/getstores')
    // eslint-disable-next-line
  }, [])

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  console.log(get)
  const data = get.response

  if (data.totalCnt === 0) return <div>No Store found.</div>
  return <Stores stores={data} />
}

function Stores({ stores }) {
  return (
    <ul className="stores">
      {stores.map((store) => (
        <li key={store.storeCode} className="store">
          <figure>
            {store.storeUrl && store.storeUrl !== '' && store.buyOnline ? (
              <a
                href={store.storeUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`/assets/shops/${store.iconPath}`}
                  alt={store.storeName}
                />
                <figcaption>Buy online</figcaption>
                {/*<figcaption>{store.storeName}</figcaption>*/}
              </a>
            ) : (
              <Fragment>
                <img
                  src={`/assets/shops/${store.iconPath}`}
                  alt={store.storeName}
                />
                <figcaption>{store.storeName}</figcaption>
              </Fragment>
            )}

            {/* <a href={store.storeUrl} target="_blank" rel="noopener noreferrer">
            Buy online <span className="icon"></span>
          </a> */}
          </figure>
        </li>
      ))}
    </ul>
  )
}
