/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect, Fragment } from "react";
import { MdClose } from "react-icons/md";
import { createPortal } from "react-dom";
import { mq } from '../../../cssInJs';
//import '../scss/index.scss';

function Portal({ children }) {
    const [el] = useState(document.createElement("div"));
    useEffect(() => {
        let mount = document.getElementById("portal-root");
        mount.appendChild(el);
        return () => mount.removeChild(el);
    }, [el]);
    return createPortal(children, el);
}

const differences = [
    {
        id: 1,
        title: "A Roast You Can Boast",
        img: "/assets/gourmetpro/a_roast_you_can_boast_round.png",
        img2: "/assets/gourmetpro/a_roast_you_can_boast.jpg",
        description: "Introducing Turbo Roast, the game changer that delivers a succulent roast in just 40 minutes. Harnessing the extraordinary speed of highspeed convection, steam and air fry, your dinner will be ready in just a fraction of the time. Prepare to impress and savour every tender bite."
    },
    {
        id: 2,
        title: "Rise to Every Challenge",
        img: "/assets/gourmetpro/rise_to_every_challenge_round.png",
        img2: "/assets/gourmetpro/rise_to_every_challenge.jpg",
        description: "Become the master baker you've always aspired to be with our Steam Assist feature. Watch your creations rise to perfection with a burst of steam, resulting in bakery-quality results. Indulge in flawlessly fluffy cakes, bread, and pastries that will leave your taste buds begging for more."
    },
    {
        id: 3,
        title: "The Steam Dream",
        img: "/assets/gourmetpro/the_steam_dream_round.png",
        img2: "/assets/gourmetpro/the_steam_dream.jpg",
        description: "Discover the effortless path to healthy cooking with Super Steam. Experience the fast and even distribution of steam, preserving vital nutrients and enhancing the flavour of your dishes. From vibrant vegetables to delectable seafood, every bite will be a celebration of freshness and wellness."
    },
    {
        id: 4,
        title: "Enjoy Guilt Free Crisp",
        img: "/assets/gourmetpro/enjoy_guilt_free_grisp_round.png",
        img2: "/assets/gourmetpro/enjoy_guilt_free_crisp.jpg",
        description: "Experience guilt-free indulgence with Gourmet Pro, harnessing the power of hot air to create the perfect crunch in every bite. Say goodbye to traditional frying methods with an air fry function that uses top-down convection to evenly distribute hot air ensuring your food achieves that coveted crispy texture on the outside, while remaining tender and juicy on the inside."
    },
    {
        id: 5,
        title: "Save Time & Money",
        img: "/assets/gourmetpro/save_time_money_round.png",
        img2: "/assets/gourmetpro/save_time_money.jpg",
        description: "Enjoy reduced cooking times by an incredible 40%, allowing you to prepare meals faster without compromising quality. The minimal pre-heating requirement of the 20L oven cavity ensures efficient energy usage, helping you cut down on running costs compared to a conventional oven."
    }
];

export function DifferenceInfoPanel() {

    const s = getStyle();
    const [openPopup, setOpenPopup] = useState(false);
    const [differenceItem, setDifferenceItem] = useState();


    function closeCookingCompanions() {
        setOpenPopup(false);
        document.body.style.overflow = "unset";
    }

    function open(difference) {
        setOpenPopup(true);
        setDifferenceItem(difference);
        document.body.style.overflow = "hidden";
    }

    return (
        <Fragment>

            {
                openPopup &&
                <Portal>
                    <div css={s.portal__background} className="portal__background">
                        <div
                            className="portal__close__btn"
                            css={s.portal__close__btn}
                            onClick={closeCookingCompanions}
                        >
                            <MdClose />
                        </div>
                    </div>
                    <div className="gourmet__pro__difference__contetns" css={s.gourmet__pro__difference__contetns}>
                        <div className="gourmet__pro__difference__contetns__item" css={s.gourmet__pro__difference__contetns__item}>
                            <div css={s.gourmet__pro__difference__contetns__bg} className="gourmet__pro__difference__contetns__bg" style={{ backgroundImage: `url("${differenceItem.img2}")` }}></div>
                            <div css={s.gourmet__pro__difference__contetns__des} className="gourmet__pro__difference__contetns__des">

                                <div css={s.gourmet__pro__difference__contetns__des__c} className="gourmet__pro__difference__contetns__des__c">
                                    <div css={s.gourmet__pro__difference__contetns__title} className="gourmet__pro__difference__contetns__title">
                                        {differenceItem.title}
                                    </div>
                                    <div css={s.gourmet__pro__difference__contetns__des__des} className="gourmet__pro__difference__contetns__des__des">
                                        {differenceItem.description}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Portal>
            }

            <section id="gourmet__pro__difference" className="gourmet__pro__difference" css={s.gourmet__pro__difference}>
                <div className="gourmet__pro__difference__items" css={s.gourmet__pro__difference__items}>
                    {
                        differences.map((difference, index) => {
                            return (
                                <div key={difference.id} className="gourmet__pro__difference__item" css={s.gourmet__pro__difference__item}>
                                    <div className="gourmet__pro__difference__icon" css={s.gourmet__pro__difference__icon} onClick={() => {
                                        open(difference);
                                    }}>
                                        <img width='100%' src={difference.img} alt={difference.title} />
                                    </div>
                                    <div className="gourmet__pro__difference__title" css={s.gourmet__pro__difference__title}>
                                        {difference.title}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        </Fragment>


    )
}

function getStyle() {
    const style = {

        portal__background: {
            backgroundColor: "rgba(0,0,0,0.8)",
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            zIndex: "9998",
        },

        portal__close__btn: {
            position: "fixed",
            zIndex: "9999",
            right: 0,
            top: 0,
            margin: "1rem",
            cursor: "pointer",
            svg: {
                width: "30px",
                height: "30px",
                color: "white",
            },
        },

        gourmet__pro__difference: {
            paddingTop: '3rem',
            paddingBottom: '3rem',
            backgroundColor: '#f1eee9'
        },
        gourmet__pro__difference__items: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            maxWidth : '1180px',
            margin : 'auto'
        },
        gourmet__pro__difference__item: mq({
            flex: ['1 0 100%', null, '1 0 33.3%'],
            maxWidth: ['100%', null, '33.3%'],
            padding: '1rem',
            '&:hover': {
                'img': {
                    transform: 'scale(1.02)',
                },
                '.gourmet__pro__difference__title': {
                    color: '#97c424'
                }
            }
        }),
        gourmet__pro__difference__icon: mq({
            maxWidth: ['220px', null, "300px"],
            maxHeight: ['220px', null, "300px"],
            margin: "auto",
            cursor: 'pointer',
            //overflow : 'hidden',
            // '&:hover' : {
            //     'img' : {
            //         transform: 'scale(1.02)',
            //     }
            // },
            'img': {
                transition: 'all 0.3s ease-in-out'
            }
        }),
        gourmet__pro__difference__title: {
            textAlign: 'center',
            fontWeight: 700,
            marginTop: '1rem'
        },

        gourmet__pro__difference__contetns: {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
            width: '100%',
            padding: '1rem'
        },

        gourmet__pro__difference__contetns__item: {
            display: 'flex',
            maxWidth: '1152px',
            margin: 'auto',
            animation: 'fadeIn 1s',
            flexWrap: 'wrap'
        },

        gourmet__pro__difference__contetns__bg: mq({
            flex: ['1 0 100%', null, '1 0 50%'],
            maxWidth: ['100%', null, '50%'],
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingTop: ['56.25%', null, '30%']
        }),

        gourmet__pro__difference__contetns__des: mq({
            flex: ['1 0 100%', null, '1 0 50%'],
            maxWidth: ['100%', null, '50%'],
            backgroundColor: 'white',
            position: 'relative'
            //padding : '1rem'
        }),

        gourmet__pro__difference__contetns__des__c: mq({
            position: ['static', null, 'absolute'],
            top: '50%',
            left: '50%',
            transform: ['none', null, 'translate(-50%, -50%)'],
            width: ['auto', null, '85%'],
            padding: '1rem'
        }),

        gourmet__pro__difference__contetns__title: {
            fontSize: '1.2rem',
            color: 'black',
            fontWeight: 700
        },

        gourmet__pro__difference__contetns__des__des: {
            marginTop: '1rem'
        }
    }
    return style;
}