/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './tileStyle';

import utils from '../../../utils';
import { Item } from '../../items/item';

export function VideoHolder(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems);

  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  const cssClass = utils.classNames('cms_item video_holder', item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} css={style.holder} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <div className="tiles" css={style.tiles}>
        {subItemsElts}
      </div>
    </div>
  );
}