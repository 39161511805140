/** @jsx jsx */
import { jsx } from '@emotion/core';
import Slider from "react-slick";
import style from './style';
import utils from '../../../utils';
import cmsUtils from '../../../cms/utils/cmsUtils';
import { SiteLink } from '../../../components';
import env from '../../../env';
import { Fragment } from 'react';


export function KsjbPageCarousel(props) {

    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const subItems = item.items || [];
    const cssClass = utils.classNames('cms_item', 'ksjb__page__carousel', item.cssClass || item.anchorName || '');

    const carouselItems = subItems.map((item, index) => {
        const imageUrl = cmsUtils.payload(item, 'ImageUrl');
        const linkUrl = cmsUtils.payload(item, 'LinkUrl') || '/';
        const captionTitle = cmsUtils.payload(item, 'CaptionTitle');

        return (
            <div key={index} className="carousel__item" css={style.carousel__item}>
                <SiteLink to={linkUrl}>
                    <img src={"https://cuisinart.com.au" + imageUrl} alt={env.siteName} />
                    <div className='carousel__item__cation' css={style.carousel__item__cation}>
                        {captionTitle}
                    </div>
                </SiteLink>
            </div>
        )
    });

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        autoplaySpeed: 3000,
        //fade: true,
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    const settings2 = {
        dots: false,
        //infinite: true,
        infinite: false,
        speed: 1500,
        autoplaySpeed: 3000,
        //fade: true,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        //afterChange : (current) => console.log("ddddd", current, next),
        //centerMode : true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    return (
        <Fragment>
            <div className={cssClass} css={style.ksjb__page__carousel} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
                <div className='carousel__1__item' css={style.carousel__1__item}>
                    <Slider {...settings}>
                        {carouselItems}
                    </Slider>
                </div>
                <div className='carousel__2__item' css={style.carousel__2__item}>
                    <div>
                        <Slider {...settings2}>
                            <div className="carousel__item" css={style.carousel__item}></div>
                            {carouselItems}
                            <div className="carousel__item" css={style.carousel__item}></div>
                        </Slider>
                    </div>
                </div>

                <div className='floating-carrot'>
                    <img src='/assets/carrot.png?v=1' alt="carrot" />
                </div>
                <div className='floating-spinach-below'>
                    <img src='/assets/spinach-below-blur25.png?v=1' alt='spinach' />
                </div>
                <div className='floating-strawberry'>
                    <img src='/assets/strawberry.png?v=1' alt='strawberry' />
                </div>

            </div>
        </Fragment>
    )
}