import { css } from '@emotion/core';
import { container } from '../siteStyle';
import { mq } from '../../cssInJs';

export default {
  productList: css(container, {
    marginTop: 40,
    marginBottom: 40,
  }),
  categoryImg: {
    margin: 10
  },
  products: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  productCol: mq({
    flex: ['1 0 100%', '1 0 50%'],
    display: 'flex',
    flexDirection: 'column',
    maxWidth: [null, '50%']
  }),
}