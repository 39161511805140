/** @jsx jsx */
import { jsx } from "@emotion/core";
import styleDefault from "./tileStyle";
import styleBig from "./tileStyle--big";
import styleRecipe from "./tileStyle--recipe";

//import React from 'react';
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";

import Slider from "react-slick";

export function PageTiles(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);
  const useSlider = cmsUtils.payload(item, "UseSlider") === "slider";
  const sliderStyle = cmsUtils.payload(item, "SliderStyle", "default");
  let style = "";

  if (sliderStyle === "big") {
    style = styleBig;
  } else if (sliderStyle === "recipe") {
    style = styleRecipe;
  } else {
    style = styleDefault;
  }

  const pages = getPages(cmsUtils.payload(item, "PageObjects"));
  const pageTileElts =
    sliderStyle === "big" ? (
      pages.map((page, index) => (
        <TileBig
          page={page}
          key={index}
          disableLink={cmsOption.isCmsEdit}
          useSlider={useSlider}
        />
      ))
    ) : sliderStyle === "recipe" ? (
      <div className='recipe_container'>
        <h4 className='recipe_header'>
          <strong>Related Recipes</strong>
        </h4>
        <div className='pageTile_container'>
          {pages.map((page, index) => (
            <TileRecipe
              page={page}
              key={index}
              disableLink={cmsOption.isCmsEdit}
              useSlider={useSlider}
            />
          ))}
        </div>
      </div>
    ) : (
      pages.map((page, index) => (
        <Tile
          page={page}
          key={index}
          disableLink={cmsOption.isCmsEdit}
          useSlider={useSlider}
        />
      ))
    );

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    //fade: false,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', `tileStyle--${sliderStyle}`, useSlider && 'useSlider', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames(
    "cms_item",
    item.cssClass || item.anchorName || ""
  );

  if (useSlider) {
    return (
      <div
        className={cssClass}
        css={style.holder}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div css={[style.tiles, useSlider && style.tilesSlider]}>
          <Slider {...settings}>{pageTileElts}</Slider>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={cssClass}
        css={style.holder}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div css={style.tiles}>{pageTileElts}</div>
      </div>
    );
  }

  function Tile({ page, disableLink, useSlider }) {
    const imageUrl = utils.site.resourcePath(page.imageUrl);
    return (
      <div css={[style.tile, useSlider && style.tileSlider]}>
        <SiteLink
          css={style.tileBg}
          style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          to={disableLink ? "" : page.pageUrl}
        ></SiteLink>
        <div className='pageTile_details tile__details'>
          <div css={style.title}>{page.pageTitle}</div>
          {(page.tileDesc || page.description) && (
            <div css={style.desc}>{page.tileDesc || page.description}</div>
          )}
        </div>
        <div css={style.readMore}>
          <SiteLink
            css={style.readMoreBtn}
            to={disableLink ? "" : page.pageUrl}
          >
            READ MORE
          </SiteLink>
        </div>
      </div>
    );
  }

  function TileBig({ page, disableLink, useSlider }) {
    const imageUrl = utils.site.resourcePath(page.imageUrl);
    return (
      <div css={[style.tile, useSlider && style.tileSlider]}>
        <SiteLink css={style.linkWrapper} to={disableLink ? "" : page.pageUrl}>
          <div
            css={style.tileBg}
            style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          ></div>
          <div className='tile__details'>
            <div css={style.title} className='tileTitle'>
              {page.pageTitle}
            </div>
          </div>
        </SiteLink>
      </div>
    );
  }

  function TileRecipe({ page, disableLink, useSlider }) {
    const imageUrl = utils.site.resourcePath(page.imageUrl);
    return (
      <div css={[style.tile, useSlider && style.tileSlider]}>
        <SiteLink css={style.linkWrapper} to={disableLink ? "" : page.pageUrl}>
          <div
            css={style.tileBg}
            style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          ></div>
          <div className='tile__details'>
            <div css={style.title} className='tileTitle'>
              {page.pageTitle}
            </div>
          </div>
        </SiteLink>
      </div>
    );
  }
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}
