import { css } from '@emotion/core';
import { variables, mq } from '../../../cssInJs';

export default {
  holder: css(mq({
    maxWidth: 1400,
    width: ['100%', null, 'calc(100% - 60px)'],
    margin: 'auto',
    marginTop: [10, null, 10],
    marginBottom: [10, null, 10],
    '.slick-prev, .slick-next': {
    },
    '.slick-prev': {
      left: [10, null, -30]
    },
    '.slick-next': {
      right: [10, null, -30]
    },
    '.slick-prev:before, .slick-next:before': {
      color: variables.fontColor
    }
  }))
}