/** @jsx jsx */
import { jsx } from "@emotion/core";
//import { Fragment } from "react";
import { useState, useEffect, Fragment, useRef } from "react";
import { createPortal } from "react-dom";
import { MdClose } from "react-icons/md";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { BsDot } from "react-icons/bs";
import { mq } from "../../../cssInJs";

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

const companions = [
  {
    id: 1,
    title: "Tri-Steam Technology",
    icon: "/assets/gourmetpro/tri_steam_technology_round_icon.png",
    icon2: "/assets/gourmetpro/tri_steam_technology_icon.png",
    img: "/assets/gourmetpro/tri-steam-technology.jpg",
    description:
      "Steam cooking in an oven is a fantastic way to cook healthy and delicious meals. By cooking with steam, your food retains its natural moisture, flavour, and nutrients. The Gourmet Pro is designed with 3 steam ports to distribute steam evenly throughout the cooking chamber, ensuring that your dishes are cooked to perfection. Unlike conventional ovens that use dry heat, the steam gently envelops the food, which helps it cook more evenly and prevents it from drying out. This even distribution of steam ensures that every part of your dish, from top to bottom, is cooked uniformly. Additionally, the moist environment created by steam cooking helps to increase tenderness, making meat and fish dishes melt-in-your-mouth delicious.",
  },
  {
    id: 2,
    title: "Fast Cooking Times",
    icon: "/assets/gourmetpro/fast_cooking_times_round_ icon.png",
    icon2: "/assets/gourmetpro/fast_cooking_times_icon.png",
    img: "/assets/gourmetpro/fast_cooking-time.jpg",
    description:
      "The Gourmet Pro is perfect for anyone looking to save time while cooking. This oven has a faster heat up time than conventional ovens, reducing preheating time. In addition, the powerful heating elements and high-speed convection fan work together to circulate hot air, ensuring that food is cooked evenly and up to 40% faster. It’s no wonder that benchtop ovens have become a popular choice for those seeking to save time in the kitchen.",
  },
  {
    id: 3,
    title: "Set & Forget Pre-sets",
    icon: "/assets/gourmetpro/set_forget_pre_sets_round_ icon.png",
    icon2: "/assets/gourmetpro/set_forget_pre_sets_icon.png",
    img: "/assets/gourmetpro/set_forget_pre_sets.jpg",
    description: "The pre-set menu is especially helpful for busy individuals or families who are juggling multiple tasks and don't have the time to closely monitor their cooking. With a simple touch of a button, choose from 20 pre-set functions for the type of dish you're preparing, such as baking, roasting air frying or steaming. It's like having a personal chef in your kitchen!",
  },
  {
    id: 4,
    title: "Dual Cooking Capabilities",
    icon: "/assets/gourmetpro/dual-cook-2-round.png",
    icon2: "/assets/gourmetpro/dual-cook-icon.png",
    img: "/assets/gourmetpro/dual_cook.jpg",
    description: "Combine 2 cooking functions with different temperatures and run them consecutively - ideal for recipes with multiple steps. Make the perfect pork belly by air frying the crackling and then switching to convection bake - and let the oven do the thinking for you!",
  },
  {
    id: 5,
    title: "Convenience at Your Fingertips",
    icon: "/assets/gourmetpro/convenience_at_your_fingertips_round_icon.png",
    icon2: "/assets/gourmetpro/convenience_at_your_fingertips_icon.png",
    img: "/assets/gourmetpro/convenience_at_your_fingertips.jpg",
    description: "Gourmet Pro not only offers convenient cooking features but also adds a touch of modern sophistication to your kitchen. With a sleek touch screen control panel, your combi-oven becomes a stylish and user-friendly appliance. The touch screen control panel on a bench top oven brings convenience right at your fingertips. Easily navigate through the oven's settings and features with a simple touch. The intuitive interface makes it easy to select cooking modes, adjust temperature and time, and explore additional functions like convection or steam settings. The touch screen control panel simplifies the cooking process by offering a seamless and responsive user experience.",
  },
  {
    id: 6,
    title: "Effortless Steam Cleaning",
    icon: "/assets/gourmetpro/effortless-steam-cleaning-round-icon.png",
    icon2: "/assets/gourmetpro/effortletless-steam-cleaning.png",
    img: "/assets/gourmetpro/effortless-steam-cleaning.jpg",
    description: "The Gourmet Pro takes the hassle out of post-cooking cleanup. With the power of steam, it effortlessly dissolves and loosens even the toughest food residues and grease, so you can simply wipe clean.",
  },
  {
    id: 7,
    title: "Large Capacity, Small Footprint",
    icon: "/assets/gourmetpro/large-capacity-small-footprint-round-icon.png",
    icon2: "/assets/gourmetpro/large-capacity-small-footprint.png",
    img: "/assets/gourmetpro/large-capacity-small-footprint .jpg",
    description: "Don't let the sleek, compact design fool you. This oven has a spacious cooking capacity allowing you the freedom to cook a chicken up to 2.5kg and a 30cm pizza. You will be amazed at how much you can cook in one go, perfect for entertaining guests or preparing the family dinner.",
  }
];

export function CookingCompanion() {
  const s = getStyle();
  const [openPopup, setOpenPopup] = useState(false);
  const [current, setCurrent] = useState();
  const gourmetItemsRef = useRef(null);
  //const dotsRef = useRef(null);

  function openCookingCompanions(index) {
    //setCurrent(companionId);
    setOpenPopup(true);

    if (window.innerWidth >= 1200) {
      setTimeout(() => {
        const _d = (window.innerWidth - (1152 + 48)) / 2;
        gourmetItemsRef.current.style.transform = `translateX(${_d}px)`;
      }, 100);

      setTimeout(() => {
        gourmetItemsRef.current.style.display = "flex";
      }, 150);

      setTimeout(() => {
        const _d = (window.innerWidth - (1152 + 48)) / 2 - index * (1152 + 48);
        gourmetItemsRef.current.style.transform = `translateX(${_d}px)`;
      }, 200);
    } else {
      setTimeout(() => {
        gourmetItemsRef.current.style.transform = `translateX(0%)`;
      }, 100);

      setTimeout(() => {
        gourmetItemsRef.current.style.display = "flex";

        if (window.innerWidth <= 768) {
          const _gourmetItems = gourmetItemsRef.current.querySelectorAll('.gourmet__pro__cooking__item');
          const _height = _gourmetItems[0].querySelector(".gourmet__pro__cooking__bg").offsetHeight;
          document.getElementById("gourmet__pro__cooking__arrow__p").style.top = (_height / 2) + "px";
          document.getElementById("gourmet__pro__cooking__arrow__n").style.top = (_height / 2) + "px";
          //document.getElementById("gourmet__pro__cooking__dots").style.top = (_height - 20) + "px";
          document.getElementById("gourmet__pro__cooking__dots").style.top = "unset";
          document.getElementById("gourmet__pro__cooking__dots").style.bottom = "-25px";
        }

      }, 150);

      setTimeout(() => {
        const _d = -(index * 100);
        gourmetItemsRef.current.style.transform = `translateX(${_d}%)`;
      }, 200);
    }

    setCurrent(index);
    document.body.style.overflow = "hidden";
  }

  function prev() {
    if (current === 0) {
      return;
    }

    const _current = current - 1;

    if (window.innerWidth >= 1200) {
      const _d = (window.innerWidth - (1152 + 48)) / 2 - _current * (1152 + 48);
      gourmetItemsRef.current.style.transform = `translateX(${_d}px)`;
    } else {
      const _d = -(_current * 100);
      gourmetItemsRef.current.style.transform = `translateX(${_d}%)`;
    }

    setCurrent(_current);
  }

  function next() {
    if (current === 6) {
      return;
    }

    const _current = current + 1;

    if (window.innerWidth >= 1200) {
      const _d = (window.innerWidth - (1152 + 48)) / 2 - _current * (1152 + 48);
      gourmetItemsRef.current.style.transform = `translateX(${_d}px)`;
    }
    else {
      const _d = -(_current * 100);
      gourmetItemsRef.current.style.transform = `translateX(${_d}%)`;
    }

    setCurrent(_current);
  }

  function closeCookingCompanions() {
    setOpenPopup(false);
    document.body.style.overflow = "unset";
  }

  return (
    <Fragment>
      {openPopup && (
        <Portal>
          <div css={s.portal__background} className="portal__background">
            <div
              className="portal__close__btn"
              css={s.portal__close__btn}
              onClick={closeCookingCompanions}
            >
              <MdClose />
            </div>
          </div>

          <div
            className="gourmet__pro__cooking__contents"
            css={s.gourmet__pro__cooking__contents}
          >

            <div className="gourmet__pro__cooking__contents__inner" css={s.gourmet__pro__cooking__contents__inner}>

              <div
                ref={gourmetItemsRef}
                className="gourmet__pro__cooking__items"
                css={s.gourmet__pro__cooking__items}
              >
                {companions.map((companion, index) => {
                  return (
                    <div
                      key={companion.id}
                      className="gourmet__pro__cooking__item"
                      css={s.gourmet__pro__cooking__item}
                    >
                      <div
                        className="gourmet__pro__cooking__bg"
                        style={{ backgroundImage: `url("${companion.img}")` }}
                      ></div>
                      <div className="gourmet__pro__cooking__des">
                        <div className="gourmet__pro__cooking__des__icon">
                          <img
                            src={companion.icon2}
                            width="100%"
                            alt={companion.title}
                          />
                        </div>
                        <div className="gourmet__pro__cooking__des__title">
                          {companion.title}
                        </div>
                        <div className="gourmet__pro__cooking__des__des">
                          {companion.description}
                        </div>
                      </div>
                    </div>
                  );
                })}

              </div>

              <div
                id="gourmet__pro__cooking__arrow__p"
                className="gourmet__pro__cooking__arrow__p"
                css={s.gourmet__pro__cooking__arrow__p}
                onClick={prev}
              >
                <IoIosArrowDropleft />
              </div>

              <div
                id="gourmet__pro__cooking__arrow__n"
                className="gourmet__pro__cooking__arrow__n"
                css={s.gourmet__pro__cooking__arrow__n}
                onClick={next}
              >
                <IoIosArrowDropright />
              </div>

              <div id="gourmet__pro__cooking__dots" className="gourmet__pro__cooking__dots" css={s.gourmet__pro__cooking__dots}>
                {
                  companions.map((companion, index) => {
                    return (
                      <div key={companion.id} className={`${current === index ? 'current ' : ''}gourmet__pro__cooking__dot`}css={s.gourmet__pro__cooking__dot}>
                        <BsDot />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>

        </Portal>
      )}

      <section
        className="gourmet__pro__cooking__companion"
        style={{ backgroundColor: "#f1eee9" }}
      >
        <div>
          <div className="gpcc__buttons" css={s.gpcc__buttons}>
            {companions.map((companion, index) => {
              return (
                <div
                  key={companion.id}
                  className="gpcc__button"
                  css={s.gpcc__button}
                >
                  <div
                    className="gpcc__button__icon"
                    css={s.gpcc__button__icon}
                    onClick={() => {
                      openCookingCompanions(index);
                    }}
                  >
                    <img
                      width="100%"
                      src={companion.icon}
                      alt={companion.title}
                    />
                  </div>
                  <div
                    className="gpcc__button__title"
                    css={s.gpcc__button__title}
                  >
                    {companion.title}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Fragment>
  );
}

function getStyle() {
  const style = {
    gpcc__buttons: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: 'center',
      maxWidth : '1300px',
      margin : 'auto'
    },
    gpcc__button: mq({
      flex: ["1 0 100%", null, 'unset', null, '14.2%'],
      maxWidth: ["100%", null, "unset", null, '14.2%'],
      cursor: "pointer",
      //paddingLeft: '0.5rem',
      //paddingRight: '0.5rem',
      padding: "0.5rem",
      '&:hover': {
        '.gpcc__button__icon img': {
          transform: 'scale(1.05)'
        },
        '.gpcc__button__title': {
          //textDecoration: 'underline'
          color: '#97c424'
        }
      }
    }),

    gpcc__button__icon: {
      //paddingLeft: '1rem',
      //paddingRight: '1rem',
      maxWidth: "124px",
      maxHeight: "124px",
      margin: "auto",
      //overflow: 'hidden',
      'img': {
        transition: 'all 0.3s ease-in-out'
      }
    },

    gpcc__button__title: {
      fontWeight: 700,
      //maxWidth: '180px',
      margin: "auto",
      marginTop: "1rem",
      maxWidth: "140px",
      textAlign: "center",
    },

    portal__background: {
      backgroundColor: "rgba(0,0,0,0.8)",
      position: "fixed",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      zIndex: "9998",
    },

    portal__close__btn: {
      position: "fixed",
      zIndex: "9999",
      right: 0,
      top: 0,
      margin: "1rem",
      cursor: "pointer",
      svg: {
        width: "30px",
        height: "30px",
        color: "white",
      },
    },

    gourmet__pro__cooking__contents: {
      position: "fixed",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 9999,
      width: "100%",
    },

    gourmet__pro__cooking__contents__inner: {
      position: 'relative'
    },

    gourmet__pro__cooking__items: {
      display: "none",
      transition: "all 1s cubic-bezier(0.22, 0.61, 0.36, 1) 0s",
      //position: 'relative'
    },

    gourmet__pro__cooking__item: mq({
      display: "flex",
      flexWrap: "wrap",
      maxWidth: "1152px",
      flexDirection: ['column', null, 'row'],
      flex: "1 0 100%",
      marginLeft: ["unset", null, null, null, "3rem"],
      padding: ["1rem", null, null, null, "unset"],

      "&:first-of-type": {
        marginLeft: "0rem",
      },

      ".gourmet__pro__cooking__bg": mq({
        flex: ["0 0 auto", null, "1 0 40%"],
        maxWidth: ["100%", null, "40%"],
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingTop: ["56.25%", null, "unset"],
      }),
      ".gourmet__pro__cooking__des": mq({
        flex: ["1 0 auto", null, "1 0 60%"],
        maxWidth: ["100%", null, "60%"],
        padding: ["1rem", null, "3rem"],
        backgroundColor: "white",
        ".gourmet__pro__cooking__des__icon": mq({
          maxWidth: ["30px", null, "50px"],
        }),
        ".gourmet__pro__cooking__des__title": {
          fontWeight: 700,
          color: "black",
          marginTop: "1rem",
          fontSize: "1.2rem",
        },
        ".gourmet__pro__cooking__des__des": {
          marginTop: "1rem",
          fontSize: "0.8rem",
        },
      }),
    }),

    gourmet__pro__cooking__arrow__p: mq({
      position: "absolute",
      left: "10px",
      top: ['26.5%', null, '50%'],
      transform: ['unset', null, 'translateY(-50%)'],
      zIndex: 9999,
      cursor: "pointer",
      svg: mq({
        width: ['30px', null, "50px"],
        height: ['30px', null, "50px"]
        //color : 'white'
      }),
    }),

    gourmet__pro__cooking__arrow__n: mq({
      position: "absolute",
      top: ['26.5%', null, '50%'],
      right: "10px",
      transform: ['unset', null, 'translateY(-50%)'],
      zIndex: 9999,
      cursor: "pointer",
      svg: {
        width: ['30px', null, "50px"],
        height: ['30px', null, "50px"]
        //color : 'white'
      },
    }),

    gourmet__pro__cooking__dots: mq({
      position: 'fixed',
      top: ['32%', null, '96%'],
      display: 'flex',
      zIndex: 9999,
      left: '50%',
      transform: 'translateX(-50%)',
    }),
    gourmet__pro__cooking__dot: mq({
      padding: ['unset', null, '1rem'],
      'svg': {
        fontSize: '40px'
      },
      '&.current svg' : {
        color: 'white'
      } 
    })

  };
  return style;
}
