import { fb, validators } from '../../form';
//import utils from '../../utils';

export function getFormData(data) {
  const model = fb.group({
    name: [data.name || '', [validators.Required()], { label: 'Name', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email Address', type: 'email' }],
    //hairType: [data.hairType || '', [validators.Required()], { label: 'Hair type', type: 'text' }],
    //hairLength: [data.hairLength || '', [validators.Required()], { label: 'Hair length', type: 'text' }],
    // hairConcerns: [data.name || '', [validators.Required()], {
    //   label: 'Hair concerns', type: 'textarea',
    //   style: { rows: 3 }
    // }],
    favouriteProduct: [data.favouriteProduct || '', [validators.Required()], {
      label: 'What are your current favourite Cuisinart products you’d love to use / trial', type: 'textarea',
      style: { rows: 3 }
    }],
    reason: [data.reason || '', [validators.Required()], {
      label: 'Why do you think you’d be a fabulous Cuisinart Ambassador?', type: 'textarea',
      style: { rows: 5 }
    }],
    website: [data.website || '', [], { label: 'Website/blog URL', type: 'text' }],
    websiteVisitor: [data.websiteVisitor || '', [], { label: 'Visitors per month', type: 'text' }],
    instagram: [data.instagram || '', [], { label: 'Instagram profile', type: 'text' }],
    instagramAuFollowers: [data.instagramAuFollowers || '', [], { label: 'No.  of AUS followers', type: 'text' }],
    instagramNzFollowers: [data.instagramNzFollowers || '', [], { label: 'No.  of NZ followers', type: 'text' }],
    facebook: [data.facebook || '', [], { label: 'Facebook profile', type: 'text' }],
    facebookAuFollowers: [data.facebookAuFollowers || '', [], { label: 'No.  of AUS followers', type: 'text' }],
    facebookNzFollowers: [data.facebookNzFollowers || '', [], { label: 'No.  of NZ followers', type: 'text' }],
    
    youtube: [data.website || '', [], { label: 'YouTube channel', type: 'text' }],
    youtubeAuFollowers: [data.websiteVisitor || '', [], { label: 'No.  of AUS followers', type: 'text' }],
    youtubeNzFollowers: [data.websiteVisitor || '', [], { label: 'No.  of NZ followers', type: 'text' }],

    tiktok: [data.tiktok || '', [], { label: 'Tiktok profile', type: 'text' }],
    tiktokAuFollowers: [data.tiktokAuFollowers || '', [], { label: 'No.  of AUS followers', type: 'text' }],
    tiktokNzFollowers: [data.tiktokNzFollowers || '', [], { label: 'No.  of NZ followers', type: 'text' }]

  });

  return model;
}