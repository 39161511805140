import React from "react";
import { SiteLink } from "../../components";

export function Breadcrumb({ breadcrumb }) {
  const containsAirPurificationLink = breadcrumb.items?.find(
    (item) => item.link === "/air-purification"
  );
  if (containsAirPurificationLink) {
    containsAirPurificationLink.link = "/products";
  }

  return (
    <ul className='breadcrumb'>
      {breadcrumb.items.map((item, index) => Item(item, index))}
    </ul>
  );
}

function Item(item, index) {
  if (item.link) {
    return (
      <li key={index}>
        <SiteLink to={item.link}>{item.title}</SiteLink>
      </li>
    );
  } else {
    return <li key={index}>{item.title}</li>;
  }
}
