import { mq } from '../../cssInJs';

export default {

    video__embed__container: mq({
        //height: ['55vh',null,'88vh']
        //height: ['55vh',null,'70vh']
        height: ['480px', '600px', '630px', null, '720px', '914px']
    }),

    video__embed__video__bg: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden'
    },

    video__embed__video: mq({
        width: ['220%','265%','auto',null,null,'auto'],
        height: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minHeight: '100%',
        //minWidth: '101%'
        minWidth: '100%'
    }),
    video__embed__text: mq({
        position: 'absolute',
        color: 'black',
        fontWeight: 700,

        // fontSize: ['28px', 'calc(16px + (32 - 16)*(100vw - 400px)/(800 - 400))','calc(16px + (30.3 - 16)*(100vh - 400px)/(800 - 400))', '33px', null, '36px', '48px'],
        // lineHeight: ['30px', '1.06', '35px', null, '38px', '51px'],
        
        fontSize: ['28px', 'calc(20px + (35 - 20)*(100vw - 400px)/(800 - 400))', '33px', null, '36px', '48px'],
        lineHeight: ['30px', '1.06', '35px', null, '38px', '51px'],

        width: '100%',
        left: '50%',
        bottom: '10%',
        textAlign: 'center',
        transform: 'translateX(-50%)',

        // '@media (min-width : 2560px) and (min-height : 1307px)': {
        //     fontSize: '48px',
        //     lineHeight: '51px'
        // }

    })

}