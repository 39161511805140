import { css } from "@emotion/core";
import { mq } from "../../cssInJs";

export default {
  top: css(
    mq({
      height: [44, null, 56],
      background: "black",
      position: "fixed",
      width: "100%",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.3s",
      "@media(min-width: 1366px)": {
        height: 80,
      },
    })
  ),
  topScrolled: css(
    mq({
      height: [44],
      "@media(min-width: 1366px)": {
        height: 56,
      },
    })
  ),
  logo: css(mq({})),
  logoImg: css(
    mq({
      display: "block",
      transition: "all 0.3s",
      marginTop: [-0.365],
      height: [18.44],
      "@media(min-width: 1366px)": {
        marginTop: -0.66 /*2/101 * h*/,
        height: 33.527 /*80 / (241/101)*/,
      },
    })
  ),
  logoImgScrolled: css(
    mq({
      "@media(min-width: 1366px)": {
        marginTop: -0.467 /*2/101 * h*/,
        height: 23.469 /*56 / (241/101)*/,
      },
    })
  ),

  buyNow: mq({
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "#97c424",
    lineHeight: 1,
    padding: ['17px 0.5rem',null,'20px 1rem'] ,
    transition: "all 0.2s ease-in-out",
    fontSize: ["10px", null, "1rem"],
    right: ['45px', '60px', '100px'],
    "@media(min-width: 1366px)": {
      padding: "32px 1rem",
      right: '100px',
    },

    a: {
      color: "white",
      fontWeight: 600,
      "&:hover": {
        textDecoration: "none",
      },
    },
  }),

  buyNowScrolled: mq({
    padding: ["17px 0.5rem"],
    "@media(min-width: 1366px)" : {
      padding : '20px 1rem'
    }
  }),
};
