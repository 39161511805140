import utils from '../../utils';

export function loadApi(onApiReady) {
  
  console.log("12312312312312313");
  
  if (typeof window !== `undefined` && !window.YT) { // If not, load the script asynchronously
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';

    // onYouTubeIframeAPIReady will load the video after the script is loaded
    window.onYouTubeIframeAPIReady = () => onApiReady(true);

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  } else { // If script is already there, load the video directly
    onApiReady(false);
  }
}

// export function player(playerId, videoId, playerVars = {}, events = {}) {
//   //let player = null;
//   const defaultPlayerVars = {
//     'autoplay': 1,
//     'controls': 0,
//     'loop': 1,
//     'showinfo': 0,
//     'rel': 0,
//     'modestbranding': 1,
//     'origin': window.location.origin,
//     'allowfullscreen': true,
//     'wmode': 'transparent'
//   }
//   const defaultEvents = {
//     'onStateChange': (e) => { },
//     'onReady': (e) => { console.log('onReady'); player.mute() },
//     'onError': (e) => { console.log(e) }
//   }

//   const player = new window.YT.Player(playerId, {
//     videoId: videoId,
//     playerVars: Object.assign({}, defaultPlayerVars, playerVars),
//     events: Object.assign({}, defaultEvents, events),
//   });
//   return player;
// }

export function thumbnailMax(videoId){
  return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
}

// onPlayerStateChange(event){
//   switch (event.data){
//     case window['YT'].PlayerState.PLAYING:
//       //console.log('Playing');
//       //this.player.mute();
//       break;
//     case window['YT'].PlayerState.PAUSED:
//       //console.log('Pafdsu');
//       break;
//     case window['YT'].PlayerState.ENDED:
//       //console.log('ended ');
//       //this.player.playVideo();
//       this.player.stopVideo();
//       break;
//   }
// }

// toggleBannerYoutueInCMS(toggleItem:string){ 
//   if(toggleItem == 'youtube'){
//     //location.reload();
//     this.toggleBannerOrYoutube = toggleItem;
//     this.player.playVideo();
//     this.init();
//   }
//   else{
//     this.toggleBannerOrYoutube = toggleItem;
//     this.player.stopVideo();
//   }
// }


export function getYoutubeCode(src) {
  // if (src.indexOf('http') >= 0) {
  //   if (src.indexOf('v=') >= 0) {
  //     return src.split('v=')[1];
  //   }
  // }
  // return src;
  return utils.url.getYoutubeId(src);
}