import { fb, validators } from "../../../lib/form";
import utils from "../../../utils";

let stateList = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"].map((x) => {
  return { name: x, value: x };
});
stateList = [{ name: "State*", value: "" }, ...stateList];

export function getContactModel(data) {
  const fields = {
    ...{
      modelNumber: [data.modelNumber || "", [], { label: "Product Model Number", type: "text" }],
      name: [data.name || "", [validators.Required()], { label: "Name", type: "text" }],
      phone: [data.phone || "", [validators.Required()], { label: "Phone", type: "text" }],
      email: [data.email || "", [validators.Required(), validators.Email()], { label: "Email", type: "email" }],
      enquiry: [data.enquiry || "", [validators.Required()], { label: "Your Message", type: "textarea" }],
    },
    ...(utils.site.isNZ
      ? {}
      : {
          state: [data.state || "", [validators.Required()], { label: null, type: "select", options: stateList }],
        }),
  };

  const model = fb.group(fields);

  return model;
}