/** @jsx jsx */
import { jsx } from '@emotion/core';
import { isMin } from '../../cssInJs'

import style from './categoryStyle';
import mobileStyle from './categoryMobileStyle';
import tileStyle from '../productTile/productTileStyle';

import React from 'react';
import env from '../../env';
import { usePost } from '../../components';
import { ErrorPopup, Loading } from '../../components';

import { Tile } from '../productTile/productTile';

import { useBV } from '../bazzarVoice/useBV';
import utils from '../../utils';
import { debounce } from '../../lib/utils-core/debounce';

export function ProductCategory({ category }) {

  const post = usePost();

  const [screen, setScreen] = React.useState('desktop');

  useBV(null, { showReview: null });
  const searchParam = { category: category };

  React.useEffect(() => {
    setScreen(isMin.md() ? 'desktop' : 'mobile')
    const resized = debounce(() => {
      //console.log(isMin.md())
      setScreen(isMin.md() ? 'desktop' : 'mobile')
    }, 100)

    window.addEventListener('resize', resized)
    return () => {
      window.removeEventListener('resize', resized)
    }
  }, [])

  React.useEffect(() => {
    post.send(env.apiBase + '/api/product/getProducts', searchParam);
    // eslint-disable-next-line
  }, []);

  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }

  console.log(post)
  const data = post.response.results.searchResult;
  const categoryImageUrl = utils.site.resourcePath(data.categoryImageUrl);

  let filtered = data.items
  
  if (data.totalCnt === 0) return <div>No product found.</div>

  function ProductTile({ inx }) {
    if (filtered[inx]) {
      return <Tile product={filtered[inx]} style={tileStyle} useFullHeight={inx > 3}></Tile>
    } else {
      return null;
    }
  }

  function MobileTiles() {
    return (
      <div css={mobileStyle.productList}>
        <div css={mobileStyle.categoryImg}>
          <img src={categoryImageUrl} alt={category} />
        </div>
        <div css={mobileStyle.products}>
          {filtered.map((product, index) =>
            <div css={mobileStyle.productCol} key={filtered[index].productCode} >
              <Tile product={filtered[index]} style={tileStyle}></Tile>
            </div>
          )}
        </div>
        {/* {showMoreButton && (<div style={{ textAlign: 'center' }}><button className="btn btn-primary" onClick={(e) => { setShowAll(true) }}>Show All</button></div>)} */}
      </div>
    );
  }

  function DesktopTiles() {
    const restProducts = filtered.length > 8 ? filtered.slice(8) : [];

    return (
      <div css={style.productList}>
        <div css={style.row1}>
          <div css={style.row1Col1}>
            <ProductTile inx={0} />
            <ProductTile inx={2} />
          </div>
          <div css={style.row1Col2}>
            <div css={style.categoryImgWrapper}>
              <div css={style.categoryImg} style={{ backgroundImage: categoryImageUrl && utils.css.bgUrlStyle(categoryImageUrl) }} ></div>
            </div>
          </div>
          <div css={style.row1Col3}>
            <ProductTile inx={1} />
            <ProductTile inx={3} />
          </div>
        </div>
        <div css={style.row2}>
          <div css={style.row2Col}>
            {filtered.length <= 6 ? <ProductTile inx={6} /> : <ProductTile inx={4} />}            
          </div>
          <div css={style.row2Col}>
            {filtered.length <= 6 ? <ProductTile inx={4} /> : <ProductTile inx={5} />} 
          </div>
          <div css={style.row2Col}>
            {filtered.length <= 6 ? <ProductTile inx={5} /> : <ProductTile inx={6} />} 
          </div>
          <div css={style.row2Col}>
            <ProductTile inx={7} />
          </div>
        </div>
        <div css={style.row3}>
          {restProducts.map((product, index) =>
            <div css={style.row3Col} key={restProducts[index].productCode}>
              <Tile product={restProducts[index]} style={tileStyle} useFullHeight={true}></Tile>
            </div>
          )}
        </div>
        {/* {showMoreButton && (<div style={{ textAlign: 'center' }}><button className="btn btn-primary" onClick={(e) => { setShowAll(true) }}>Show All</button></div>)} */}
      </div>
    );
  }

  
  if (screen === 'desktop') {
    return <DesktopTiles />
  } else {
    return <MobileTiles />
  }
}
