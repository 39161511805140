import { css } from '@emotion/core';
import { container } from '../siteStyle';

export default {
  productList: css(container, {
    marginTop: 40,
    marginBottom: 40,
  }),
  row1: {
    display: 'flex',
  },
  row1Col1: {
    flex: '0 0 25%',
    display: 'flex',
    flexDirection: 'column',
  },
  row1Col2: {
    flex: '0 0 50%',
  },
  row1Col3: {
    flex: '0 0 25%',
    display: 'flex',
    flexDirection: 'column',
  },
  row2: {
    display: 'flex',
  },
  row2Col: {
    flex: '0 0 25%',
  },
  row3: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  row3Col: {
    flex: '0 0 25%',
  },
  categoryImgWrapper: {
    width: '100%',
    height: '100%',
    padding: 7
  },
  categoryImg: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }
}