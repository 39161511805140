/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useRef } from "react";
import Slider from "react-slick";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { mq } from "../../../cssInJs";
import { SiteLink } from "../../../components";

const tiles = [
  {
    id: 1,
    title: "Caramalised Banana Cake",
    img: "/assets/gourmetpro/caramalised_banana_cake.jpg",
    description: "",
    link: "/recipes/caramelised-banana-cake",
  },
  {
    id: 2,
    title: "Sticky Asian Pork Ribs",
    img: "/assets/gourmetpro/sticky_asian_pork_ribs.jpg",
    description: "",
    link: "/recipes/sticky-asian-pork-ribs",
  },
  {
    id: 3,
    title: "Sweet Miso Glazed Eggplant Poke Bowl",
    img: "/assets/gourmetpro/sweet_miso_glazed_eggplant_poke_bowl.jpg",
    description: "",
    link: "/recipes/sweet-miso-glazed-eggplant-poke-bowl",
  },
  {
    id: 4,
    title: "Strawberry, Rhubarb and Rose Cobbler",
    img: "/assets/gourmetpro/strawberry_rhubarb_rose_cobbler.jpg",
    description: "",
    link: "/recipes/strawberry-rhubarb-and-rose-cobbler",
  },
  {
    id: 5,
    title: "Fig-caccia",
    img: "/assets/gourmetpro/fig_caccia.jpg",
    description: "",
    link: "/recipes/fig-caccia",
  },
  //   {
  //     id: 6,
  //     title: "Waffles",
  //     img: "https://cuisinart.com.au/sites/cuisinart/media/banners/adobestock_435799005.jpg",
  //     description: "",
  //   },
  //   {
  //     id: 7,
  //     title: "Waffles",
  //     img: "https://cuisinart.com.au/sites/cuisinart/media/banners/adobestock_435799005.jpg",
  //     description: "",
  //   },
  //   {
  //     id: 8,
  //     title: "Waffles",
  //     img: "https://cuisinart.com.au/sites/cuisinart/media/banners/adobestock_435799005.jpg",
  //     description: "",
  //   },
  //   {
  //     id: 9,
  //     title: "Waffles",
  //     img: "https://cuisinart.com.au/sites/cuisinart/media/banners/adobestock_435799005.jpg",
  //     description: "",
  //   },
  //   {
  //     id: 10,
  //     title: "Waffles",
  //     img: "https://cuisinart.com.au/sites/cuisinart/media/banners/adobestock_435799005.jpg",
  //     description: "",
  //   },
];

export function SlidingRecipeTiles() {
  const s = getStyle();
  const sliderRef = useRef(null);

  const settings = {
    //dots: true,
    speed: 1000,
    infinite: true,
    //autoplaySpeed: 3000,
    //fade: true,
    //autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function prev() {
    sliderRef.current.slickPrev();
  }

  function next() {
    sliderRef.current.slickNext();
  }

  return (
    <section
      id="gourmet__pro__sliding__recipe"
      className="gourmet__pro__sliding__recipe"
      css={s.gourmet__pro__sliding__recipe}
    >
      <div
        className="gourmet__pro__sliding__recipe__c"
        css={s.gourmet__pro__sliding__recipe__c}
      >
        <div
          className="gourmet__pro__sliding__recipe__title"
          css={s.gourmet__pro__sliding__recipe__title}
        >

          <div className="gourmet__pro__sliding__recipe__title__text desktop">
            Be inspired to <br />
            elevate your cooking to <br/>
            new heights with these <br />
            tasty new recipes
          </div>

          <div className="gourmet__pro__sliding__recipe__title__text mobile">
            Be inspired to elevate your <br/>
            cooking to new heights with these <br />
            tasty new recipes
          </div>

          <div className="gourmet__pro__sliding__recipe__title__arrow">
            <IoIosArrowDropleft onClick={prev} />
            <IoIosArrowDropright onClick={next} />
          </div>

        </div>
        <div
          className="gourmet__pro__sliding__recipe__tiles"
          css={s.gourmet__pro__sliding__recipe__tiles}
        >
          <Slider ref={sliderRef} {...settings}>
            {tiles.map((tile, index) => {
              return (
                <div key={tile.id}>
                  <SiteLink to={tile.link}>
                    <div
                      className="gourmet__pro__sliding__recipe__tile__c"
                      css={s.gourmet__pro__sliding__recipe__tile__c}
                    >
                      <div
                        className="gourmet__pro__sliding__recipe__tile"
                        css={s.gourmet__pro__sliding__recipe__tile}
                        style={{ backgroundImage: `url("${tile.img}")` }}
                      >
                        {/* <img src={tile.img} width="100%" /> */}
                        <div className="gourmet__pro__sliding__recipe__tile__t">
                          {tile.title}
                        </div>
                      </div>
                    </div>
                  </SiteLink>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
}

function getStyle() {
  const style = {
    gourmet__pro__sliding__recipe: {
      paddingTop: "3rem",
      paddingBottom: "3rem",
      backgroundColor: "white",
      ".slick-slide > div": { lineHeight: 0 },
      ".slick-prev, .slick-next": {
        visibility: "hidden",
      },
    },
    gourmet__pro__sliding__recipe__c: {
      display: "flex",
      flexWrap: "wrap",
    },
    gourmet__pro__sliding__recipe__title: mq({
      flex: ["1 0 100%", null, "1 0 20%"],
      maxWidth: ["100%", null, "250px"],
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      ".gourmet__pro__sliding__recipe__title__text": {
        textAlign: "center",
        color: "#706F6F",
        fontWeight: 600,
        
        // ".gourmet__pro__sliding__recipe__title__br": mq({
        //   display: ["none", null, "inline"],
        // }),
        // ".gourmet__pro__sliding__recipe__title__br__m": mq({
        //   display: ["inline,", null, "none"],
        // }),

        '&.desktop' : mq({
          display: ["none", null, "block"]
        }),
        '&.mobile' : mq({
          display: ["block", null, "none"]
        })

      },
      ".gourmet__pro__sliding__recipe__title__arrow": {
        textAlign: "center",
        marginTop: "1rem",
        svg: {
          width: "40px",
          height: "40px",
          marginLeft: "1rem",
          marginRight: "1rem",
          cursor: "pointer",
        },
      },
    }),
    gourmet__pro__sliding__recipe__tiles: mq({
      flex: ["1 0 100%", null, '"1 0 80%"'],
      maxWidth: ["100%", null, "calc(100% - 250px)"],
      //paddingRight: "1rem",
      //paddingLeft: "1rem",
      //display: 'inline-block'
    }),

    gourmet__pro__sliding__recipe__tile__c: {
      margin: "0.5rem",
      overflow: "hidden",
      cursor: "pointer",
      "&:hover": {
        ".gourmet__pro__sliding__recipe__tile": {
          transform: "scale(1.02)",
        },
      },
    },

    gourmet__pro__sliding__recipe__tile: mq({
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      paddingTop: ["100%", null, "56.25%"],
      //margin: "0.5rem 0.5rem",
      position: "relative",
      transition: "all 0.3s ease-in-out",
      "&:after": {
        content: "''",
        position: "absolute",
        top: "0",
        width: "100%",
        height: "100%",
        backgroundImage:
          "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.0), rgba(0,0,0,1))",
      },
      ".gourmet__pro__sliding__recipe__tile__t": {
        position: "absolute",
        bottom: "5px",
        padding: "1rem",
        color: "white",
        fontWeight: 600,
        zIndex: 2,
        lineHeight: "1.1",
      },
    }),
  };
  return style;
}
