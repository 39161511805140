/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from '../videoHolder/tileStyle';

import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { YoutubeEmbedPopupV2 } from '../../../components/youtubeEmbedPopupV2/youtubeEmbedPopupV2';

export function VideoItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  const youtubeCode = cmsUtils.payload(item, 'YoutubeCode');
  const posterFrameUrl = cmsUtils.payload(item, 'PosterFrameUrl');
  const captionTitleYoutube = cmsUtils.payload(item, 'CaptionTitleYoutube');
  const shortDesc = cmsUtils.payload(item, 'ShortDesc');
  const cssClass = utils.classNames('cms_item', item.cssClass || item.anchorName || '');
  const posterFrameUrlType = (posterFrameUrl && posterFrameUrl !== '') ?  (posterFrameUrl.includes('.webm') ? 'webm' : 'mp4') : '';

  return (
    <div css={style.tile} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <div className="tile__bg">
        <YoutubeEmbedPopupV2 url={youtubeCode} title={captionTitleYoutube} posterUrl={posterFrameUrl} type={posterFrameUrlType} />
      </div>
      <div className="tile__details">
        <div className="title" css={style.title}>
          {captionTitleYoutube}
        </div>
        <div className="desc" css={style.desc}>
          <div dangerouslySetInnerHTML={{ __html: shortDesc }}></div>
        </div>
      </div>
    </div>
  );
}