import React from 'react';
import env from '../../../env';
import utils from '../../../utils';
import {
  EmailShareButton,
  FacebookShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon
} from "react-share";
import { FiPrinter } from 'react-icons/fi';
import './printShare.scss';
import cmsUtils from '../../utils/cmsUtils';

export function PrintShare({ item, cmsOption }) {
  const shareUrl = env.baseOrigin + cmsOption.pageData.pageUrl;
  const bannerUrl = getBanner(cmsOption.pageData);
  console.log(cmsOption, shareUrl, bannerUrl)
  return <React.Fragment>
    <div className="printShare">
      <button className="iconBtn" onClick={() => { window.print(); }} title="Print"><FiPrinter size={25} /></button>
      <EmailShareButton url={shareUrl} title="Send Email">
        <EmailIcon size={50} round={true} />
      </EmailShareButton>
      <FacebookShareButton url={shareUrl} title="Sharing with Facebook">
        <FacebookIcon size={50} round={true} />
      </FacebookShareButton>
    </div>
    {bannerUrl && <div className="forPrint"><img src={utils.site.resourcePath(bannerUrl)} alt="For Print" /></div>}
  </React.Fragment>;
}

function getBanner(pageData) {
  const banner = cmsUtils.findItemByTypeCode(pageData, 'BannerItem')
  //console.log( banner)
  if (banner) {
    return cmsUtils.payload(banner, 'ImageUrl')
  }
  else{
    const singleBanner = cmsUtils.findItemByTypeCode(pageData, 'SingleBanner');
    if(singleBanner){
      return cmsUtils.payload(singleBanner, 'ImageUrl');
    }
  }
  return null;
}