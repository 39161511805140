/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useRef } from "react";
import { mq } from '../../../cssInJs';
import { IoIosArrowUp } from "react-icons/io";
//import '../scss/index.scss';

const informationsData = [
    {
        id: 'steam',
        title: "Steam",
        img: "/assets/gourmetpro/steam.jpg",
        description: "Achieve moist and flavourful results every time. From vegetables to seafood, steam cooking preserves nutrients and enhances the natural flavours of your food.",
        open: false
    },
    {
        id: 'airFry',
        title: "Air Fry",
        img: "/assets/gourmetpro/airfry.jpg",
        description: "Craving crispy yet healthier meals? The air frying feature delivers delectable golden-brown results without the excess oil. Enjoy guilt-free versions of your favourite fried dishes, such as French fries, chicken wings, and nuggets.",
        open: false
    },
    {
        id: 'turboRoast',
        title: "Turbo Roast",
        img: "/assets/gourmetpro/turbo-roast.jpg",
        description: "Roasting has never been faster with the Gourmet Pro. This feature uses a combination of high speed convection and steam to allow for a high temperature and even heat distribution, giving you succulent, juicy meats.",
        open: false
    },
    {
        id: 'steamAssist',
        title: "Steam Assist",
        img: "/assets/gourmetpro/steam-assist-2.jpg",
        description: "The steam assist function simulates a bakers oven by combining an initial burst of steam with the power of convection cooking for crispy results and soft risen interiors.",
        open: false
    },
    {
        id: 'convectionBake',
        title: "Convection Bake",
        img: "/assets/gourmetpro/convection-bake.jpg",
        description: "This feature allows for even and consistent heat distribution, giving you perfect cakes, pies, and roasted meats. You can count on getting impeccable results every time.",
        open: false
    },
    {
        id: 'bakeSteam',
        title: "Bake Steam",
        img: "/assets/gourmetpro/bake-steam-2.jpg",
        description: "Utilises convection bake and steam for the entire cooking process achieving tender juicy results on the inside, with crisp on the outside",
        open: false
    },
    {
        id: 'superSteam',
        title: "Super Steam",
        img: "/assets/gourmetpro/super-steam.jpg",
        description: "Cook the healthy way, faster and more evenly. This feature adds extra heat to steam cooking, ideal for rice, custards and poultry.",
        open: false
    },
    {
        id: 'grill',
        title: "Grill",
        img: "/assets/gourmetpro/grill.jpg",
        description: "Perfect for open cheese melts, browning lasagne or grilling vegetables.",
        open: false
    },
    {
        id: 'toast',
        title: "Toast",
        img: "/assets/gourmetpro/toast.jpg",
        description: "Toast bread, bagels, English muffins, and more! With the Toast function, you can achieve the perfect level of toasting for your breakfast.",
        open: false
    },
    {
        id: 'warm',
        title: "Warm",
        img: "/assets/gourmetpro/warm.jpg",
        description: "Need to quickly reheat your leftovers to retain original flavour and texture? Use the Warm feature which is perfect for reheating or keeping your dishes warm until it's time to serve. You no longer have to worry about food getting cold before you’re ready to enjoy it.",
        open: false
    }
];

export function InfoPanel2() {
    const s = getStyle();
    const [informations, setInformations] = useState(informationsData);
    const [currentInfo, setCurrentInfo] = useState(informations[0]); 
    const informRef = useRef(null);
    
    function changeCurrentInfo(info) {
        //setCurrentInfo(null);
        // setTimeout(() => {
        //     setCurrentInfo(info);
        // }, 100);

        if (info.id === currentInfo.id) {
            return;
        }
        informRef.current.classList.remove('infor__transition');
        setTimeout(() => {
            setCurrentInfo(info);
            informRef.current.classList.add('infor__transition');
        }, 100);
    }

    function openInfoM(index) {
        informations[index].open = !informations[index].open;
        setInformations(JSON.parse(JSON.stringify(informations)));
    }

    return (
        <section id="gourmet__pro__info__p2" className="gourmet__pro__info__p2" css={s.gourmet__pro__info__p2}>
            <div className="gourmet__pro__info__p2__c" css={s.gourmet__pro__info__p2__c}>
                <div className="gourmet__pro__info__p2__menu" css={s.gourmet__pro__info__p2__menu}>
                    {
                        informations.map((info, index) => {
                            return (
                                <div key={info.id} className={`gourmet__pro__info__p2__menu__item ${currentInfo.id === info.id ? 'current' : 'not__current'}`} css={s.gourmet__pro__info__p2__menu__item} onClick={() => {
                                    changeCurrentInfo(info);
                                }}>
                                    {info.title}
                                </div>
                            )
                        })
                    }
                </div>

                <div ref={informRef} className="gourmet__pro__info__p2__info infor__transition" css={s.gourmet__pro__info__p2__info} style={{ backgroundImage: `url("${currentInfo.img}")` }}>
                    {/* <img width='100%' src={currentInfo.img} alt={currentInfo.id} /> */}
                    <div className="gourmet__pro__info__p2__info__ccc" css={s.gourmet__pro__info__p2__info__ccc}>
                        <div className="gourmet__pro__info__p2__info__title" >{currentInfo.title}</div>
                        <div className="gourmet__pro__info__p2__info__des">{currentInfo.description}</div>
                    </div>
                </div>
            </div>

            <div className="gourmet__pro__info__p2__c__m" css={s.gourmet__pro__info__p2__c__m}>
                {
                    informations.map((info, index) => {
                        return (
                            <div key={info.id} className={`${info.id} gourmet__pro__info__p2__item__m`} css={[s.gourmet__pro__info__p2__item__m]}>
                                <div className={`${info.id} gourmet__pro__info__p2__info__title__m${info.open ? ' open' : ''}`} css={s.gourmet__pro__info__p2__info__title__m} onClick={() => {
                                    openInfoM(index);
                                }}>
                                    {info.title}
                                    <IoIosArrowUp />
                                </div>
                                <div className={`gourmet__pro__info__p2__info__ccc__m${info.open ? ' open' : ''}`} css={s.gourmet__pro__info__p2__info__ccc__m}>
                                    <div className="gourmet__pro__info__p2__info__img__m" css={s.gourmet__pro__info__p2__info__img__m}>
                                        <img width="100%" src={info.img} alt={info.title} />
                                    </div>
                                    <div className="gourmet__pro__info__p2__info__des__m" css={s.gourmet__pro__info__p2__info__des__m}>
                                        {info.description}
                                    </div>
                                </div>

                            </div>
                        )
                    })
                }
            </div>

        </section>
    )
}

function getStyle() {
    const style = {
        gourmet__pro__info__p2: {
            paddingTop: '3rem',
            paddingBottom: '3rem',
            backgroundColor: 'white'
        },
        gourmet__pro__info__p2__c: mq({
            display: ['none', null, 'flex'],
            maxHeight: '496px'
        }),
        gourmet__pro__info__p2__menu: {
            flex: '1 0 20%',
            maxWidth: '380px',
            minWidth: '236px',
            overflow : 'auto',
            
            '::-webkit-scrollbar' : {
                width: '10px'
            },

            '::-webkit-scrollbar-track' : {
                background: '#f1f1f1'
            },

            '::-webkit-scrollbar-thumb' : {
                background: '#e2e2e2',
                borderTopRightRadius: '5px',
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px'
            },

            '::-webkit-scrollbar-thumb:hover' : {
                background: '#e2e2e2'
            }

            
        },
        gourmet__pro__info__p2__menu__item: {
            padding: '1rem 1.5rem',
            //color: 'black',
            borderTop: '1px solid #706F6F',
            fontWeight: 600,
            cursor: 'pointer',
            transition: 'all 0.3s ease-in-out',
            fontSize: '1.2rem',
            '&:hover, &.current': {
                backgroundColor: '#97c424',
                color: 'white'
            },
            '&:last-of-type': {
                borderBottom: '1px solid #706F6F',
            }
        },
        gourmet__pro__info__p2__info: {
            //flex: '1 0 80%',
            flex: '1 0 68%',
            maxWidth : '100%',
            position: 'relative',
            overflow: 'hidden',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            marginLeft: '2px',
            '&.infor__transition': {
                animation: 'fadeIn 1s',
            },
            // 'img': {
            //     position: 'absolute',
            //     top: '50%',
            //     left: '50%',
            //     transform: 'translate(-50%, -50%)'
            // }
        },
        gourmet__pro__info__p2__info__ccc: {
            position: 'absolute',
            top: '1rem',
            left: '1rem',
            padding: '1rem',
            maxWidth: '370px',
            backgroundColor: 'white',
            '.gourmet__pro__info__p2__info__title': {
                fontSize: '1.2rem',
                fontWeight: 700, 
            },
            '.gourmet__pro__info__p2__info__des': {
                marginTop: '0.5rem'
            }
        },

        //mobile
        gourmet__pro__info__p2__c__m: mq({
            display: ['block', null, 'none'],
            paddingLeft: '1rem',
            paddingRight: '1rem'
        }),
        gourmet__pro__info__p2__item__m: {
            //color: 'black',
            borderTop: '1px solid #706F6F',
            borderLeft: '1px solid #706F6F',
            borderRight: '1px solid #706F6F',
            '&.warm': {
                borderBottom: '1px solid #706F6F',
                overflow: 'hidden',
                borderBottomLeftRadius : '8px',
                borderBottomRightRadius : '8px'
            },
        },

        gourmet__pro__info__p2__info__ccc__m : {
            maxHeight: '0px',
            transition: 'all 0.5s ease-in-out',
            overflow: 'hidden',
            '&.open' : {
                maxHeight: '1000px',
                transition: 'all 0.8s ease-in-out'
            }
        },

        gourmet__pro__info__p2__info__title__m: {
            cursor: 'pointer',
            fontWeight: 700,
            fontSize: '1rem',
            padding: '1rem 1.5rem',
            position : 'relative',
            '&:hover': {
                backgroundColor: '#97c424',
                color: 'white'
            },
            '&.warm': {
                //borderBottom: '1px solid #706F6F',
            },
            'svg' : {
                position : 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%) rotate(180deg)',
                width: '20px',
                height: '20px',
                transition: 'all 0.3s ease-in-out'
            },
            '&.open svg' : {
                transform: 'translateY(-50%) rotate(0deg)',
            }
        },
        gourmet__pro__info__p2__info__img__m: {
            //marginTop: '2px'
        },

        gourmet__pro__info__p2__info__des__m: mq({
            padding: '1rem',
            fontSize: '0.85rem'
        })

    }

    return style
}