/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './tileStyle';

//import React from 'react';
import utils from '../../utils';
import { SiteLink } from '../../components';

import Slider from "react-slick";

export function ProductTiles({ products }) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    //fade: false,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  return (
    <div css={style.holder}>
      <div css={[style.tiles, style.tilesSlider]}>
        <Slider {...settings} >
          {products.map((product, index) =>
            <Tile product={product} key={index} />
          )}
        </Slider>
      </div>
    </div>
  );
}



function Tile({ product }) {
  const imageUrl = utils.site.resourcePath(product.productThumbnail);
  return (
    <div css={[style.tile, style.tileSlider]}>
      <SiteLink css={style.productLink} to={product.productUrl}>
        <div css={style.tileBg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
      </SiteLink>
    </div>
  )
}
