const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"Montserrat",sans-serif',
  familyHeader: '"Montserrat",serif',
  
  primaryColor: '#97c424',
  fontColor: '#706f6f',
  bgColor: '#f1eee9',
  greenColor2 : '#9bb652',
  linkHoverColor: '#769528',

  ...overloading
}

export default variables;
