import { variables, mq } from '../../cssInJs';

export default {
  footerWrapper: {
    background: 'black',
    padding: '2rem 1rem 9rem'
  },
  footer: {
    maxWidth: 960,
    margin: 'auto',
    fontFamily: variables.familyHeader,
  },
  logo: {
    borderBottom: 'solid 2px #555',
    paddingBottom: '1rem',
  },
  logoImg: mq({
    maxHeight: [20, null, 22],
    //marginLeft: [10]
  }),
  socialAndLinks: mq({
    paddingTop: [10, null, 15]
  }),
  social: {
    marginLeft: '-10px',
  },
  socialIcon: {
    width: '30px',
    height: '30px',
    marginLeft: '10px',
    marginRight: '10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: '.3s',
    display: 'inline-block',
  },

  links: mq({
    textAlign: ['left', null, 'right'],
    marginTop: [15, null, 5],
    fontSize: '0.75rem',
    color: '#ebebeb;',

    'a': {
      color: '#ebebeb;',
      fontSize: '0.75rem',
    },
    'a:hover, a:focus': {
      color: '#fff'
    }
  })
}