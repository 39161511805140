/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './tileStyle';

import Slider from "react-slick";
import utils from '../../utils';
import { SiteLink } from '../../components';

export function ProductTiles({ products }) {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplaySpeed: 3000,
    //fade: false,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  return (
    <div css={style.holder}>
      <div css={[style.tiles, style.tilesSlider]}>
        <Slider {...settings} >
          {products.map((product, index) =>
            <Tile product={product} key={index} tileStyle={style} />
          )}
        </Slider>
      </div>
    </div>
  );
}


function Tile({ product, tileStyle }) {
  const style = tileStyle;
  const imageUrl = product.productThumbnail ? utils.site.resourcePath(product.productThumbnail) : '/assets/product-empty.png';
  //console.log(imageUrl)
  const productUrl = product.productUrl ? product.productUrl : `/product/${product.productCode}`;
  return (
    <div css={[style.tile, style.tileSlider]}>
      <SiteLink css={style.tileLink} to={productUrl} style={{textAlign: 'center'}}>
        <div css={style.prdImage}>
          <div css={style.bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        </div>
        <div css={style.name}>
          {product.productName}
          {/* <div className="productTile__inlineRating">
            <div data-bv-show="inline_rating" data-bv-product-id={product.productCode}></div>
          </div> */}
        </div>

        <div css={style.price}>
          {product.priceText ? product.priceText : ''}
        </div>

      </SiteLink>
    </div>
  )
}