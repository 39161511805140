/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import utils from '../../utils';

export function VideoEmbed(props) {
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'video__embed', item.cssClass || item.anchorName || '');

    // id={item.anchorName}

    return (
        <div className={cssClass} css={style.video__embed__container} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id="ksjb-page-video">
            <div className='video__embed__video__bg' css={style.video__embed__video__bg}>
                <video autoPlay="autoplay" loop poster="/sites/cuisinart/media/kick-start-landing-page/landing_masthead_3.jpg" muted playsInline className='video__embed__video' css={style.video__embed__video}>
                    {/* <source src="https://parkapiki.com/videos/home.mp4" type="video/mp4" /> */}
                    <source src="/sites/cuisinart/media/kick-start-landing-page/landing-masthead.m4v" type="video/mp4" />
                    {/* <source src="/sites/cuisinart/media/carousel/landing-masthead.m4v" type="video/mp4" /> */}
                </video>
                <div className='video__embed__text' css={style.video__embed__text}>
                    Kick Start Personal <br /> Juicer & Blender
                </div>
            </div>
        </div>
    )
}