import { mq } from '../../cssInJs';

export default {
  instagrams: {
    marginLeft: '-10px',
    marginRight: '-10px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },	
  topPadding: mq({
    height: [24, null, 30],
    backgroundColor: 'white',
    '@media(min-width: 1366px)': {
      height: 50
    }
  }),

  qa: {
    marginBottom: '1rem',
    borderBottom: 'solid 1px #ccc',
  },
  qaQuestion: {
    fontWeight: 'bold',
    marginBottom: '0.3rem',
  },
  qaAnswer: {

  }
}