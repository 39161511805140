//import { mq } from '../../../cssInJs';

import { mq } from "../../../cssInJs";

export default{
    
    ksjb__page__carousel : {
        position: 'relative',
        '.slick-prev:before, .slick-next:before' : mq({
            fontSize:['41px',null,'50px']
        }),
        '.slick-next, .slick-prev' : mq({
            width: ['41px',null,'48px'],
            height: ['41px',null,'48px']
        }),
        '.slick-next' : mq({
            right: ['27%',null,'20px']
        }),
        '.slick-prev' : mq({
            left : ['27%',null,'20px']
        }),
        '&:after' : mq({
            content: "''",
            position:'absolute',
            width : '100%',
            height: '100%',
            top : '0px',
            left: '0px',
            display: ['block',null,'none'],
            //backgroundColor: 'red'
        })
    },
    
    carousel__item : mq({ 
        paddingLeft:['0.5rem',null,'1rem'],
        paddingRight: ['0.5rem',null,'1rem'],
        'img' : {
            width: '100%',
            borderTopLeftRadius: '22px',
            borderTopRightRadius: '22px'
        },
        'a:hover' : {
            textDecoration: 'none'
        }
    }),
    
    carousel__item__cation : {
        backgroundColor: 'white',
        textAlign: 'left',
        paddingLeft: '1rem',
        paddingTop: '0.8rem',
        paddingBottom: '1rem',
        fontWeight: 700,
        color:'black',
        fontSize: '17px',
        borderBottomLeftRadius: '22px',
        borderBottomRightRadius: '22px'
    },
    carousel__1__item : mq({
        opacity: ['0',null,'1']
    }),
    carousel__2__item : mq({
        display : ['block',null,'none'],
        position : 'absolute',
        //width:'140%',
        width:'210%',
        height : '100%',
        top : '0',
        left:'50%',
        transform: 'translateX(-50%)',
        zIndex: '1'
    })
}