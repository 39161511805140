import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';
import env from '../../../env';

export function CarouselItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));
  const linkUrl = cmsUtils.payload(item, 'LinkUrl2') || cmsUtils.payload(item, 'LinkUrl');

  const cssClass = utils.classNames('cms_item', 'carouselItem');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {linkUrl ? <SiteLink to={linkUrl} className="carouselItem__link">
        <img src={bgUrl} alt={env.siteName} />
      </SiteLink>
        : <img src={bgUrl} alt={env.siteName} />}
    </div>
  );


}
