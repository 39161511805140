import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { YoutubeBannerMobilePopup } from '../../../lib/youtube/youtubeBanner--mobilePopup';

export function YoutubeBannerPanel(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';
  const videoCode = cmsUtils.payload(item, 'YoutubeCode');
  const playerId = 'ytBanner-' + (item.itemId).substr(0, 8);

  const video = {
    heightSet: heightSet,
    videoLinkOrId: videoCode,
    playerId: playerId
  }

  //const enableYoutube = env.isProd;
  
  const cssClass = utils.classNames('cms_item', 'youtubePanel', item.cssClass || item.anchorName || '');
  //const canPlayVideo = () => utils.css.screenWidth() >= 1200;
  const canPlayVideo = (() => {
    if(cssClass.includes('gourmet-pro')){
      return true;
    }
    return utils.css.screenWidth() >= 1200;
  });

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {/* {canPlayVideo() ? <YoutubeBanner video={video} /> : <BannerPanel item={item}></BannerPanel>} */}
      <YoutubeBannerMobilePopup video={video} canPlayVideo={canPlayVideo} />
    </div>
  );

}