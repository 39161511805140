/** @jsx jsx */
import { jsx } from "@emotion/core";
import cmsUtils from "../../cms/utils/cmsUtils";
import utils from "../../utils";
import { useEffect } from "react";
import { usePost, Loading, ErrorMessage } from "../../components";
import { Tile } from "../../site/product/productTileV2";
import env from "../../env";
import { useBV } from '../../site/bazzarVoice/useBV';

export function RecipeRelProducts(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "recipe__rel__products",
    item.cssClass || item.anchorName || ""
  );

  const recipeRelatedProducts =
    cmsUtils.payload(item, "RecipeRelatedProducts") || "";
  //const recipeRelatedProducts = "";

  console.log("recipeRelatedProducts", recipeRelatedProducts);
  const post = usePost();
  useBV(null, { showReview: null });

  useEffect(() => {
    if (!recipeRelatedProducts || recipeRelatedProducts === "") {
      return;
    }

    post.send(env.apiBase + "/api/product/getrelproductsforrecipe", {
      relProductsForRecipe: recipeRelatedProducts,
    });

    // eslint-disable-next-line
  }, []);

  if (!recipeRelatedProducts || recipeRelatedProducts === "") {
    return <div> No product </div>;
  } else if (post.loading()) {
    return <Loading />;
  } else if (post.hasErrors()) {
    return <ErrorMessage title="Loading Error" errors={post.errors} />;
  }

  //const data = post.response;
  const products = post.response;

  if (products.length === 0){
    return (
        <div className="alert alert-danger" role="alert">
          No result found.
        </div>
      );
  }
  
  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <div className="productView__relatedProducts">
        <div className="productList">
          {products.map((product, index) => (
            <Tile product={product} key={product.productCode}></Tile>
          ))}
        </div>
      </div>
    </div>
  );
}
