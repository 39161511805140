/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Item } from '../../items/item';
import cmsUtils from '../../utils/cmsUtils';
import  utils from '../../../utils';
import Slider from "react-slick";
import './carousel.scss';
import style from './style'

export function Carousel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems);

  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';

  const slides = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  var settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    //fade: true,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // ,{
      //   breakpoint: 576,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1
      //   }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  console.log(settings)
  const cssClass = utils.classNames('cms_item', 'carousel', `bannerWrapperSize--${heightSet}`, item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <div className="carouselSlides" css={style.holder}>
        <Slider {...settings} >
          {slides}
        </Slider>
      </div>
    </div>
  );
}