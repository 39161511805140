import { css  } from '@emotion/core'
import { variables, mq } from '../../cssInJs'

const step = css(mq({
  flex: '0 0 100%',
  opacity: 0,
  maxHeight: '300px',
  transition: 'all 0.3s',
  '>div': {
    paddingLeft: [5, 5, 100],
    paddingRight: [5, 5, 100],
  }
}))
const navs = {
  navs: css(mq({
    marginTop: [5, 10, 20],
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
  })),
  nav: css(mq({
    width: 20,
    height: 30,
    background: '#ccc',
    margin: 5,
    transform: 'skewX(-12deg)'
  })),
  navCurrent: css({
    backgroundColor: variables.primaryColor
  }),
  navFilled: css({
    backgroundColor: '#666'
  }),
  navClickable: css({
    cursor: 'pointer'
  })
}

export default {
  joinForm: css({
    position: 'relative',
    margin: 'auto',
    marginBottom: '3rem',
    padding: '1rem 1rem 2rem',
    background: 'rgba(255,255,255,0.9)',
    textAlign: 'left'
  }),

  stepsWrapper: css({
    overflow: 'hidden',
  }),
  steps: css({
    display: 'flex',
    transition: 'all 1s cubic-bezier(0.22, 0.61, 0.36, 1)',
    '& h3, & h4': {
      textTransform: 'uppercase'
    }
  }),
  step,
  stepCurrent: css(step, {
    maxHeight: '2000px',
    opacity: 1
  }),
  stepPrev: css(step, {}),
  stepNext: css(step, {}),
  stepLast: css({
    //maxHeight: '1000px',
    opacity: 1
  }),

  arrow: css(mq({
    position: "absolute",
    cursor: 'pointer',
    color: '#aaa',
    transition: 'all 0.3s',
    zIndex: '1',
    // xs, sm(>=576), md(>=768), lg(>=992), xl(>=1200), xxl(>=1600)
    top: 0,
    // [mqMax.md]: {
    //   width: 100
    // },
    width: [20, 25, 30, 40],
    '&:hover': {
      color: '#666'
    }
  })),
  arrowPrev: css(mq({
    left: [-15, 0]
  })),
  arrowNext: css(mq({
    right: [-15, 0]
  })),

  ...navs
}
