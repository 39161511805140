import { css } from '@emotion/core';
import { variables, mq } from '../../cssInJs';
export default {
  button: css(mq({
    fontFamily: variables.familyHeader,
    position: 'absolute',
    left: '1rem',
    lineHeight: '1.6rem',
    color: '#ddd',
    fontSize: ['0.8rem', null, '0.9rem']
  })),
}