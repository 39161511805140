import { css } from '@emotion/core';
import { variables } from '../../cssInJs';

const linkText = {
  color: variables.fontColor,
  fontSize: '0.8rem',
  padding: '0 20px',
  '&:hover': {
    color: variables.fontColor,
  }
}

export default {
  tile: {
    padding: 7,
    flex: '1 0 auto',
    fontFamily: variables.familyHeader,
    //textAlign: 'left'
    //height: '100%'
  },
  tileLink: {
    background: '#fff',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    '&:hover': {
      textDecoration: 'none',
      background: '#f3f3f3'
    }
  },
  prdImage: {
    padding: '0 5px',
    maxWidth: '190px',
    margin: 'auto',
    width: '100%',
  },
  bg: {
    height: 0,
    paddingBottom: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  name: css(linkText, {
    fontWeight: 'bold',
  }),
  price: css(linkText, {
    
    marginTop: 'auto',
  })
}