/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import utils from '../../../utils';
import { useState, useMemo, useEffect } from 'react';

export function KsjbPageMenuBar(props) {

    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'ksjb__page__menu__bar', item.cssClass || item.anchorName || '');
    const [curruentPosition, setCurruentPosition] = useState("");
    const curruentPositionMemo = useMemo(() => {
        return curruentPosition;
    }, [curruentPosition]);

    useEffect(() => {
        const handleScroll = event => {
            
            const videoPanel = document.getElementById("ksjb-page-video");
            const rectVideoPanel = videoPanel.getBoundingClientRect();
            
            const aboutPanel = document.getElementById("about");
            const rectAboutPanel = aboutPanel.getBoundingClientRect();
            
            const recipesPanel = document.getElementById("recipes");
            const rectRecipesPanel = recipesPanel.getBoundingClientRect();

            const quizPanel = document.getElementById("quiz-panel");
            const rectQuizPanel = quizPanel.getBoundingClientRect();

            //const retailersPanel = document.getElementById("retailers");
            const retailersPanel = document.getElementById("retailers-text");
            const rectRetailersPanel = retailersPanel.getBoundingClientRect();

            if (rectVideoPanel.top <= 200) {
                setCurruentPosition("");
            }
            
            if (rectAboutPanel.top <= 200) {
                setCurruentPosition("About Kick Start");
            }
            
            if (rectRecipesPanel.top <= 200) {
                setCurruentPosition("Recipes");
            }
            
            if (rectQuizPanel.top <= 200) {
                setCurruentPosition("Quiz");
            }
            
            if (rectRetailersPanel.top <= 500) {
                setCurruentPosition("Retailers");
            }

            //console.log("rectAboutPanel.top", rectAboutPanel.top);
            //console.log("rectAboutPanel.bottom", rectAboutPanel.bottom);
            // console.log("aboutPanelfff", window.pageYOffset);

            // if(rectAboutPanel.top >= 98 && rectAboutPanel.top <= 500){    
            //     console.log("sdfsfsdfsdfsdf");
            //     setCurruentPosition("About Kick Start");
            // }


        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

        // eslint-disable-next-line
    }, []);

    return (
        <div className={cssClass} css={style.ksjb__page__menu__bar} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <div className='menu__bar' css={style.menu__bar}>
                <div className='bar__item' css={style.bar__item}>
                    <span className={`bar__item__title ${curruentPositionMemo === 'About Kick Start' ? 'selected' : ''}`} css={style.bar__item__title} onClick={() => {
                        //setCurruentPosition("About Kick Start");
                        utils.ui.scrollTo("#about");
                    }}>About Kick Start
                        <hr className={`bar__item__line ${curruentPositionMemo === 'About Kick Start' ? 'selected' : ''}`} css={style.bar__item__line} />
                    </span>
                </div>
                <div className='bar__item' css={style.bar__item}>
                    <span className={`bar__item__title ${curruentPositionMemo === 'Recipes' ? 'selected' : ''}`} css={style.bar__item__title} onClick={() => {
                        //setCurruentPosition("Recipes");
                        utils.ui.scrollTo("#recipes");
                    }}>Tips
                        <hr className={`bar__item__line ${curruentPositionMemo === 'Recipes' ? 'selected' : ''}`} css={style.bar__item__line} />
                    </span>
                </div>
                <div className='bar__item' css={style.bar__item}>
                    <span className={`bar__item__title ${curruentPositionMemo === 'Quiz' ? 'selected' : ''}`} css={style.bar__item__title} onClick={() => {
                        //setCurruentPosition("Quiz");
                        utils.ui.scrollTo("#quiz-panel");
                    }}>Quiz
                        <hr className={`bar__item__line ${curruentPositionMemo === 'Quiz' ? 'selected' : ''}`} css={style.bar__item__line} />
                    </span>
                </div>
                <div className='bar__item' css={style.bar__item}>
                    <span className={`bar__item__title ${curruentPositionMemo === 'Retailers' ? 'selected' : ''}`} css={style.bar__item__title} onClick={() => {
                        //setCurruentPosition("Retailers");
                        //utils.ui.scrollTo("#retailers");
                        utils.ui.scrollTo("#retailers-text");
                    }}>Retailers
                        <hr className={`bar__item__line ${curruentPositionMemo === 'Retailers' ? 'selected' : ''}`} css={style.bar__item__line} />
                    </span>
                </div>
            </div>
        </div>
    )
}