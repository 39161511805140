import React, { lazy, Suspense } from 'react';
import { Loading } from "../../components";
const GourmetPro = lazy(() => import('./gourmetPro'));

export function GourmetProLoading(props) {
  return (
    <div>
        <Suspense fallback={<Loading />}>
            <GourmetPro />
        </Suspense>
    </div>
  );
}