import { mq } from "../../../cssInJs"

export default {
    ksjb__page__quiz__panel: {
        backgroundColor: '#ABC87A',
        //backgroundColor: 'red',
        position: 'relative'
    },
    quiz__panel__wave: mq({
        width: '100%',
        height: '25px',
        position: 'absolute',
        top: ['-24px', null, '-25px', '-27px'],
        'svg': {
            width: '100%',
            height: '100%'
        }
    }),
    m_floating_carrot: mq({
        // position: absolute;
        // max-width: 78px;
        // bottom: -38%;
        // left: 72px;
        // z-index: -1;
        // transform: rotateZ(210deg);
        // display: none;
        position: 'absolute',
        maxWidth: '78px',
        left: ' 72px',
        transform: 'rotate(210deg)',
        top: '-26%',
        display: ['block', null, 'none'],
        'img': {
            width: '100%'
        }
    }),
    floating_spinach_below: mq({
        position: 'absolute',
        maxWidth: '80px',
        left: '1%',
        top: '-30%',
        transform: 'rotate(-60deg)',
        display: ['block', null, 'none'],
        'img': {
            width: '100%'
        }
    }),
    m_floating_strawberry: mq({
        position: 'absolute',
        maxWidth: '80px',
        left: '65%',
        top: '-12%',
        transform: 'rotate(-36deg)',
        display: ['block', null, 'none'],
        'img': {
            width: '100%'
        }
    }),
    ksjb__page__quiz__inner__panel: mq({
        textAlign: 'center',
        overflow: 'hidden',
        paddingTop: ['1rem', null, '5rem'],
        paddingBottom: ['1rem', null, '4rem'],
        paddingLeft: ['0.5rem', null, '0.5rem'],
        paddingRight: ['0.5rem', null, '0.5rem']
    }),
    panel__container: {
        //maxWidth: '1600px',
        //margin: 'auto',
        display: 'flex',
        transition: 'all 1s cubic-bezier(0.22, 0.61, 0.36, 1) 0s'
    },
    step__title__container: {
        flex: '0 0 100%',
        //border: '1px solid red'
    },
    row__style: {
        alignItems: 'center',
        alignContent: 'center',
        height: '100%',
        '&.quiz__panel__title__container': {
            //maxWidth: '1600px',
            maxWidth: '1000px',
            margin: 'auto',
        },
        '.col-md-6': {
            //border: '1px solid red'
        }
    },
    quiz__panel__title: {
        color: 'black',
        //border: '1px solid red',
        //maxWidth: '800px',
        //margin: 'auto',
        '.first__text': mq({
            fontSize: ['14px', null, '23px'],
            fontWeight: 700,
            textAlign: 'center',
            letterSpacing: '5px',
            paddingLeft: '5px'
        }),
        '.second__text': mq({
            fontSize: ['26px', null, '44px'],
            fontWeight: 700,
            textAlign: 'center',
            lineHeight: '1.1',
            marginTop: ['1rem', null, '1.5rem']
        }),
        '.third__text': mq({
            fontSize: ['21px', null, '21px'],
            textAlign: 'center',
            marginTop: ['1rem', null, '1.5rme'],
            lineHeight: '1.3',
            '&.desk__top': mq({
                display: ['none', null, 'block']
            }),
            '&.mobile': mq({
                display: ['block', null, 'none'],
                fontSize: '14px',
                maxWidth: '364px',
                marginLeft: 'auto',
                marginRight: 'auto'
            })
        }),
        '.button__text': mq({
            backgroundColor: 'black',
            color: 'white',
            display: ['none', null, 'inline-block'],
            marginTop: ['1rem', null, '1.5rem'],
            fontSize: ['21px', null, '26px'],
            fontWeight: 700,
            marginLeft: 'auto',
            marginRight: 'auto',
            //width: '65%',
            borderRadius: '10px',
            paddingTop: ['6.5px', null, '6.5px'],
            paddingBottom: ['6.5px', null, '6.5px'],
            paddingLeft: '60px',
            paddingRight: '60px',
            cursor: 'pointer',
            letterSpacing: 0,
            '&:hover': {
                backgroundColor: '#4d4d4d'
            },
            '&.mobile': {
                display: ['inline-block', null, 'none'],
                marginBottom: '1rem'
            }
        })
    },

    quiz__panel__img: {
        //border: '1px solid red',
        maxWidth: '530px',
        margin: 'auto',
        'img': {
            width: '100%'
        }
    },

    quiz__detail__text1: mq({
        fontSize: ['21px', null, '23px'],
        fontWeight: 700,
        textAlign: 'center',
        color: 'black'
    }),

    quiz__detail__text2: mq({
        fontSize: ['26px', null, '48px'],
        fontWeight: 700,
        textAlign: 'center',
        lineHeight: '1.1',
        marginTop: ['0.8rem', null, '1.5rem'],
        marginBottom: ['0.8rem', null, '1.5rem'],
        color: 'black',
    }),
    quiz__detail__answers: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '970px',
        margin: 'auto'
    },
    quiz__detail__answer: mq({
        flex: ['1 0 50%', null, '1 0 33.3%'],
        maxWidth: ['50%', null, '33.3%'],
        padding: ['0.3rem', null, '1rem']
    }),
    quiz__detail__answer__des: mq({
        padding: ['0.5rem', null, '1rem'],
        border: '2px solid white',
        color: 'black',
        fontWeight: '500',
        borderRadius: '20px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: ['110px', null, '144px'],
        cursor: 'pointer',
        '&.selected': {
            backgroundColor: 'white'
        }
    }),
    quiz__detail__arrow: {
        'img': mq({
            width: ['40px', null, '60px'],
            marginLeft: '2rem',
            marginRight: '2rem',
            cursor: 'pointer',
            '&.none': {
                pointerEvents: 'none'
            }
        }),
    },
    submit__button: mq({
        backgroundColor: 'black',
        color: 'white',
        display: 'inline-block',
        marginTop: '1.5rem',
        fontSize: ['21px', null, '21px'],
        fontWeight: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '10px',
        paddingTop: ['0.5rem', null, '1rem'],
        paddingBottom: ['0.5rem', null, '1rem'],
        paddingLeft: '5rem',
        paddingRight: '5rem',
        cursor: 'pointer',
        '&.none': {
            pointerEvents: 'none'
        }
    }),
    result__step: {
        flex: '0 0 100%'
    },
    result__step__detail: mq({
        backgroundColor: '#FFFBE3',
        maxWidth: '1056px',
        margin: 'auto',
        marginTop: ['3rem', null, '3rem'],
        display: 'flex',
        borderRadius: '30px',
        padding: '1rem',
        flexWrap: 'wrap',
        '.result__step__detail__p': mq({
            flex: ['1 0 100%', null, '1 0 50%'],
            maxWidth: ['100%', null, '50%'],
            padding: '1rem'
        }),
        '.result__step__detail__title': mq({
            textAlign: 'left',
            fontWeight: '700',
            color: 'black',
            fontSize: ['21px', null, '26px']

        }),
        '.result__step__detail__title2': {
            fontSize: '36px',
            textAlign: 'left',
            lineHeight: '1.3',
            marginTop: '0.5rem',
            fontWeight: 700,
            '&.a': {
                color: '#FF8181'
            },
            '&.b': {
                color: '#FF8181'
            },
            '&.c': {
                color: '#931C4F'
            },
            '&.d': {
                color: '#DD1034'
            },
            '&.e': {
                color: '#679914'
            },
            '&.f': {
                color: '#F8B301'
            }
        },
        '.result__step__detail__img__text': {
            marginTop: '1rem',
            'img': {
                width: '100%'
            }
        },
        '.result__step__detail__img': {
            margin: 'auto',
            'img': {
                width: '100%'
            }
        },
        '.result__step__detail__des': {
            textAlign: 'left',
            marginTop: '2rem',
            fontWeight: '500',
            color: 'black'
        }
    }),

    result__step__social__v2: {
        display: 'flex',
        justifyContent : 'center',
        alignItems : 'center'
    },

    result__step__social: mq({
        paddingLeft : ['4px',null,'8px'],
        paddingRight : ['4px',null,'8px'],
        '.result__step__social__container': mq({
            display: 'inline-block',
            maxWidth: ['45px', null, '60px'],
            'img': {
                width: '100%'
            },
        }),
        'button.react-share__ShareButton:focus': {
            outline: 'none !important'
        }
    }),

    result__step__bottom__text__v2: mq({
        paddingLeft : ['4px',null,'8px'],
        paddingRight : ['4px',null,'8px'],
        '.result__step__bottom__text__1, .result__step__bottom__text__2' : {
            fontWeight:700
        },
    }),

    result__step__bottom__text: mq({
        color: 'black',
        marginTop: '2rem',
        marginBottom: ['3rem', null, '0'],
        '.result__step__bottom__text__3': mq({
            marginTop : ['0.4rem',null,'0.8rem'],
        }),
        '.result__step__quiz__btn': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    })
}