import { css } from '@emotion/core';
import { variables, mq } from '../../cssInJs';

const breakpoints = [2, 2, 3, 4]
const marginLR = [14, 14, 14, 14]
const marginTB = [14, 14, 14, 14]

const bpPer = (index) => 100 / breakpoints[index] + '%'

const linkText = {
  color: variables.fontColor,
  fontSize: '0.8rem',
  padding: '0 20px',
  '&:hover': {
    color: variables.fontColor,
  }
}

export default {
  holder: css(mq({
    maxWidth: 1000,
    margin: 'auto',
    marginTop: [20, null, 20],
    marginBottom: [20, null, 20],
    '.slick-prev, .slick-next': {
      top: '100px',
      transform: 'translate(0, 0)',
    },
    '.slick-prev': {
      left: [10, null, -20]
    },
    '.slick-next': {
      right: [10, null, -20]
    },
    '.slick-prev:before, .slick-next:before': {
      color: variables.fontColor
    }
  })),
  tiles: css(mq({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginLeft: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    marginRight: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
  })),
  tilesSlider: css(mq({
    display: 'block',
  })),
  tile: css(mq({
    paddingLeft: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    paddingRight: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    paddingTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    paddingBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    maxWidth: [bpPer(0), bpPer(1), bpPer(2), bpPer(3)],
    flexBasis: [bpPer(0), bpPer(1), bpPer(2), bpPer(3)],
    flexGrow: 0,
    flexShrink: 0,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column'
  })),
  tileSlider: css(mq({
    maxWidth: '100% !important'
  })),
  tileBg: css(mq({
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: 0,
    paddingBottom: '100%'
  })),
  title: css(mq({
    fontSize: '1.2rem',
    fontWeight: 'bold',
    margin: '0.5rem 0 0.1rem',
  })),
  desc: css(mq({
    fontSize: '0.8rem',
  })),
  productLink: css(mq({
    display: 'inline-block',
    background: variables.bgColor,
    padding: '0.1rem 0.6rem',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    border: `solid 1px ${variables.bgColor}`,
    '&:hover': {
      border: 'solid 1px #000'
    }
  })),
  tileLink: {
    background: '#fff',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    '&:hover': {
      textDecoration: 'none',
      background: '#f3f3f3'
    }
  },
  prdImage: {
    padding: '0 5px',
    maxWidth: '190px',
    margin: 'auto',
    width: '100%',
  },
  bg: {
    height: 0,
    paddingBottom: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  name: css(linkText, {
    fontWeight: 'bold'
  }),
  price: css(linkText, {
    marginTop: 'auto',
  })
}