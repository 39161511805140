//import { css } from '@emotion/core';
//import { variables, mq } from '../cssInJs';

const container = {
  maxWidth: '1000px',
  margin: 'auto',
}

export {
  container
}