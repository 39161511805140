import { css } from '@emotion/core';
import { mq } from '../../../cssInJs';

const breakpoints = [2, 2, 3, 4]
const marginLR = [14, 14, 14, 14]
const marginTB = [14, 14, 14, 14]

const bpPer = (index) => 100/breakpoints[index] + '%'

export default {
  holder: css(mq({
    maxWidth: 1000,
    margin: 'auto',
    marginTop: [30, null, 40],
    marginBottom: [30, null, 40],
  })),
  tiles: css(mq({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginLeft: [-marginLR[0]/2, -marginLR[1]/2, -marginLR[2]/2, -marginLR[3]/2],
    marginRight: [-marginLR[0]/2, -marginLR[1]/2, -marginLR[2]/2, -marginLR[3]/2],
    marginTop: [marginTB[0]/2, marginTB[1]/2, marginTB[2]/2, marginTB[3]/2],
    marginBottom: [marginTB[0]/2, marginTB[1]/2, marginTB[2]/2, marginTB[3]/2],
  })),
  tile: css(mq({
    paddingLeft: [marginLR[0]/2, marginLR[1]/2, marginLR[2]/2, marginLR[3]/2],
    paddingRight: [marginLR[0]/2, marginLR[1]/2, marginLR[2]/2, marginLR[3]/2],
    paddingTop: [marginTB[0]/2, marginTB[1]/2, marginTB[2]/2, marginTB[3]/2],
    paddingBottom: [marginTB[0]/2, marginTB[1]/2, marginTB[2]/2, marginTB[3]/2],
    maxWidth: [bpPer(0), bpPer(1), bpPer(2), bpPer(3)],
    flexBasis: [bpPer(0), bpPer(1), bpPer(2), bpPer(3)],
    flexGrow: 0,
    flexShrink: 0,
    textAlign: 'left'
  })),
  title: css(mq({
    fontSize: '1.2rem',
    fontWeight: 'bold',
    margin: '0.5rem 0 0.1rem',
  })),
  desc: css(mq({
    fontSize: '0.8rem',
  }))
}