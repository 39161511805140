/** @jsx jsx */
import { jsx } from '@emotion/core';

import React from 'react';
import env from '../../env';
import { usePost } from '../../components';
import { ErrorPopup, Loading } from '../../components';
import { ProductTiles } from '../productTile--slider/productTiles--simple';
import { container } from '../siteStyle';

export function Trending(props) {
  const post = usePost();

  React.useEffect(() => {
    post.send(env.apiBase + '/api/product/getproducts', { trendingNow: true });
    // eslint-disable-next-line
  }, []);

  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }

  const data = post.response;
  //console.log(data.results.searchResult.items)
 
  return (
    <div css={container}>
      <h3><strong>Trending Now</strong></h3>
      <div>
        <ProductTiles products={data.results.searchResult.items} />
      </div>
    </div>
  );
}