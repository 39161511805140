/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import utils from '../../utils';
import * as yt from './iframeApi';
import { FaRegPlayCircle } from 'react-icons/fa';
import { useStore } from '../../store';
import style from './youtubeEmbedPopupV2Style';

export function YoutubeEmbedPopupV2({ url, title, posterUrl, type }) {
    const { dispatch } = useStore();

    const videoId = yt.getYoutubeCode(url);
    const posterImageUrl = utils.site.resourcePath(posterUrl || yt.thumbnailMax(videoId));
    const [popupIndex, setPopupIndex] = React.useState(0);

    console.log("posterImageUrl", posterImageUrl);

    function openVideo() {
        setPopupIndex(popupIndex + 1);
        dispatch({
            type: 'SET_POPUP', payload: {
                sources: [
                    <div className='ytvideo' style={{width:'1920px'}}>
                        <iframe className='fslightbox-source fslightbox-youtube-iframe fslightbox-opacity-1' title={videoId} src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`} frameborder="0" allowfullscreen></iframe>
                    </div>
                    //'https://www.youtube.com/watch?v=' + videoId
                ], popupIndex: popupIndex + 1
            }
        });
    }

    if (type === 'mp4' || type === 'm4v') {
        return (
            <div css={style.video__embed__video__bg} onClick={openVideo}>
                <video autoPlay="autoplay" loop muted playsInline className='video__embed__video' css={style.video__embed__video}>
                    <source src={posterImageUrl} type="video/mp4" />
                </video>
                <FaRegPlayCircle className="ytEmdPopup__playIcon" />
            </div>
        )
    }

    return (
        <div className="ytEmdPopup__poster" style={{ backgroundImage: utils.css.bgUrlStyle(posterImageUrl) }} onClick={openVideo}>
            <FaRegPlayCircle className="ytEmdPopup__playIcon" />
        </div>
    );
}

// poster="/sites/cuisinart/media/kick-start-landing-page/features-conveniencetwoinone.png"
// {/* <source src={posterImageUrl} type="video/webm" /> */ }
// /sites/cuisinart/media/carousel/cut-for-product.webm