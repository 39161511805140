import { fb, validators } from '../../form';

export function getFormDataStep1(data) {
  //const genderList = ['Female', 'Male'].map(x => { return { name: x, value: x } })
  
  const model = fb.group({
    firstName: [data.firstName || '', [validators.Required()], { label: 'First Name', type: 'text' }],
    lastName: [data.lastName || '', [validators.Required()], { label: 'Last Name', type: 'text' }],
    //gender: [data.gender || [], [validators.Required()], { type: 'radioList', options: genderList, style: { inline: false } }]
  });

  return model;
}

export function getFormDataStep2(data) {
  
  const model = fb.group({
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email Address', type: 'email' }],
    mobile: [data.mobile || '', [], { label: 'Mobile (optional)', type: 'text'}]
  });

  return model;
}

export function getFormDataStep3(data) {
  const model = fb.group({
    dob : [data.dob || '', [validators.Required(), validators.PastDate('Please check the date.')], { label: 'Date of Birth', type: 'dob' }],
    postcode : [data.postcode || '', [validators.Required()], { label: 'Postcode ', type: 'text' }]
  });

  return model;
}


export function getFormDataStep4(data) {
  let hairTypeList = ['LONG', 'MEDIUM', 'SHORT', 'FINE', 'THICK', 'STRAIGHT', 'CURLY', 'NATURAL', 'FRIZZY', 'COLOURED'].map(x => { return { name: x, value: x } })
  
  const model = fb.group({
    hairTypes: [data.hairTypes || '', [validators.Required('Please select at least one.')], { label: null, type: 'checkboxList', 
      options: hairTypeList, style: { inline: true, className: 'listMin3' } }]
  });

  return model;
}


export function getFormDataStep5(data) {
  let interestList = [
    `DELICIOUS NEW RECIPES`, 
    `COOKING TIPS & HINTS`, 
    `PROMOTIONS & OFFERS`,
    `NEW PRODUCT RELEASES`
  ].map(x => { return { name: x, value: x } })

  const model = fb.group({
    interests: [data.interests || '', [validators.Required('Please select at least one.')], { label: null, type: 'checkboxList', 
      options: interestList, style: { inline: false, className: 'listMin3' } }]
  });

  return model;
}
